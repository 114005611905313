import { TextareaAutosize, TextareaAutosizeProps } from '@mui/material'
import * as React from 'react'
import { Controller, ControllerProps, FieldValues, Path } from 'react-hook-form'

type TextareaAutosizeControllerProps<TValues extends FieldValues, TName extends Path<TValues>> = Omit<
  TextareaAutosizeProps,
  'name'
> &
  Pick<ControllerProps<TValues, TName>, 'name' | 'control' | 'rules'>
export const TextareaAutosizeController = <TValues extends FieldValues, TName extends Path<TValues>>({
  name,
  control,
  rules,
  ...props
}: TextareaAutosizeControllerProps<TValues, TName>) => (
  <Controller
    render={({ field }) => {
      const id = props.id || name
      return <TextareaAutosize id={id} {...props} {...field} />
    }}
    name={name}
    control={control}
    rules={rules}
  />
)
