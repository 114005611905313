import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@alice-financial/pretext-ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Elements } from '@stripe/react-stripe-js'
import * as React from 'react'
import {
  ConfirmACHConnection,
  ConfirmStripeACHConnection,
} from '../../../externalServices/stripe/ConfirmACHConnection'
import { ConnectBankAccountForm } from '../../../externalServices/stripe/ConnectBankAccountForm'
import { useIssuingStripe } from '../../../externalServices/stripe/useLoadStripe'
import { PaymentMethodFragment } from '../../../graphql/fragments/OrganizationFragment_gen'
import { ConnectACHPaymentSourceForm, ConnectStripeACHForm } from '../../banking/ConnectACHForm'
import { ACHNumbers } from '../../banking/types'
import { useCreateACHPaymentSource } from '../../banking/useConnectACHForm'
import { useOrgId } from '../../useOrgId'
import { useIssuingClientSecret } from '../useIssuingClientSecret'
import { useStripeSetupIntentSecretQuery } from './gql/stripeSetupIntentSecret_gen'
import { TypedBankableEntity } from './types'
import { useCreateAliceCardPaymentMethod } from './useCreateAliceCardPaymentMethod'

type ConnectPaymentProps = {
  clientSecret: string
  currentPaymentMethod: PaymentMethodFragment | undefined
  onError: () => void
}

type ConnectACHProps = Omit<ConnectPaymentProps, 'currentPaymentMethod'> & {
  onSuccess: (achNumbers: ACHNumbers) => void
}
type StripeConnectOptionProps = ConnectPaymentProps & {
  onSuccess: () => void
  stripeAccountId: string
}

const ACHOption = ({ clientSecret, onSuccess, onError }: ConnectACHProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Provide account &amp; routing numbers
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ConnectStripeACHForm clientSecret={clientSecret} onSuccess={onSuccess} onError={onError} />
    </AccordionDetails>
  </Accordion>
)

type SecretACHMandateReacceptanceProps = {
  stripeAccountId: string
  currentPaymentMethod: PaymentMethodFragment
  clientSecret: string
}
const SecretACHMandateReacceptance = ({
  stripeAccountId,
  clientSecret,
}: SecretACHMandateReacceptanceProps) => {
  const [showMandate, setShowMandate] = React.useState(false)
  const clickShowMandate = () => {
    setShowMandate(true)
  }
  return (
    <>
      <Typography color="transparent" onClick={clickShowMandate} variant="caption">
        π
      </Typography>
      {showMandate && (
        <ConfirmStripeACHConnection
          clientSecret={clientSecret}
          handleConfirmationResponse={() => setShowMandate(false)}
          invalidationKeys={[useIssuingClientSecret.getKey(stripeAccountId)]}
        />
      )}
    </>
  )
}

// Login to your bank via Stripe to verify
const StripeConnectOption = ({
  currentPaymentMethod,
  clientSecret,
  onSuccess,
  stripeAccountId,
}: StripeConnectOptionProps) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
      <Typography variant="body2" color="primary" fontWeight="bold">
        Log in to verify
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <ConnectBankAccountForm clientSecret={clientSecret} onSuccess={onSuccess} />
      {currentPaymentMethod && (
        <SecretACHMandateReacceptance
          stripeAccountId={stripeAccountId}
          currentPaymentMethod={currentPaymentMethod}
          clientSecret={clientSecret}
        />
      )}
    </AccordionDetails>
  </Accordion>
)

type AddNewPaymentMethodProps = {
  onSuccess?: () => void
  entity: TypedBankableEntity
  currentPaymentMethod: PaymentMethodFragment | undefined
}
/**
 * When a new payment method needs to be connected to fund Alice Card, this component allows
 * the admin to choose between:
 * 1. Directly connect ACH using account and routing numbers
 * 2. Start the Stripe flow to connect a bank account
 *
 * Both options then require the user to accept the ACH mandate
 */
export const AddNewStripePaymentMethod = ({
  currentPaymentMethod,
  onSuccess,
  entity,
}: AddNewPaymentMethodProps) => {
  const orgId = useOrgId()
  const [showACHMandate, setShowACHMandate] = React.useState(false)
  const { data: stripeSetupIntentSecretData } = useStripeSetupIntentSecretQuery({ orgId: useOrgId() })
  const companyAccount = stripeSetupIntentSecretData?.organization?.aliceCardProgram.companyAccount
  const [routingNumber, setRoutingNumber] = React.useState<string | null>(null)
  const [accountNumber, setAccountNumber] = React.useState<string | null>(null)
  const { mutateAsync: createPaymentMethod } = useCreateAliceCardPaymentMethod(
    entity,
    { accountNumber, routingNumber },
    { onSuccess }
  )

  const { data: issuingSecret, refetch } = useIssuingClientSecret(
    companyAccount?.id,
    currentPaymentMethod?.stripeSystemId
  )
  const stripeAccountId = companyAccount?.stripeAccountId
  const stripePromise = useIssuingStripe({ stripeAccount: stripeAccountId })

  if (!stripeAccountId || !issuingSecret) return null

  return (
    <Elements stripe={stripePromise} options={{ clientSecret: issuingSecret }}>
      <Typography variant="body2" gutterBottom>
        Choose how you would like to verify the bank account that you use to withdraw payroll for{' '}
        <strong>{entity.name}</strong>
      </Typography>
      {showACHMandate ? (
        <Accordion expanded={true}>
          <AccordionDetails>
            <ConfirmStripeACHConnection
              clientSecret={issuingSecret}
              handleConfirmationResponse={createPaymentMethod}
              invalidationKeys={[useStripeSetupIntentSecretQuery.getKey({ orgId })]}
            />
          </AccordionDetails>
        </Accordion>
      ) : (
        <>
          <ACHOption
            clientSecret={issuingSecret}
            onSuccess={(achNumbers: ACHNumbers) => {
              setRoutingNumber(achNumbers.routingNumber)
              setAccountNumber(achNumbers.accountNumber)
              setShowACHMandate(true)
            }}
            onError={() => refetch()}
          />
          <StripeConnectOption
            currentPaymentMethod={currentPaymentMethod}
            clientSecret={issuingSecret}
            stripeAccountId={stripeAccountId}
            onSuccess={() => setShowACHMandate(true)}
            onError={() => refetch()}
          />
        </>
      )}
    </Elements>
  )
}

export const AddNewACHPaymentSource = ({ entity, onSuccess }: AddNewPaymentMethodProps) => {
  const [showACHMandate, setShowACHMandate] = React.useState(false)
  const [achNumbers, setAchNumbers] = React.useState<ACHNumbers | null>(null)

  const { mutate: createACHPaymentSource } = useCreateACHPaymentSource(entity, { onSuccess })
  if (achNumbers && showACHMandate) {
    return <ConfirmACHConnection onAccept={() => createACHPaymentSource(achNumbers)} />
  }
  return (
    <ConnectACHPaymentSourceForm
      onSuccess={(values) => {
        setAchNumbers(values)
        setShowACHMandate(true)
      }}
    />
  )
}
