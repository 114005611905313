export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** A String, Boolean, or Array of Strings */
  AnswerTextUnion: { input: unknown; output: unknown }
  /** An ISO 8601-encoded date */
  FormattedDate: { input: string; output: string }
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string }
  /** Represents untyped JSON */
  JSON: { input: unknown; output: unknown }
}

export enum AccessRequirementEnum {
  /** The user does not have access to the feature */
  Forbidden = 'forbidden',
  /** The user has full access to the feature */
  Ok = 'ok',
  /** The user must enter their password to access the feature */
  Password = 'password',
}

/** Physical address information for display, including verification status */
export type AddressDisplay = {
  __typename?: 'AddressDisplay'
  cityName?: Maybe<Scalars['String']['output']>
  /** Was it added by user for mailing */
  isMailing: Scalars['Boolean']['output']
  /** Verification status corresponds to passing verification check */
  isVerified: Scalars['Boolean']['output']
  line1?: Maybe<Scalars['String']['output']>
  line2?: Maybe<Scalars['String']['output']>
  stateCode?: Maybe<Scalars['String']['output']>
  /** Verification status */
  verificationStatus?: Maybe<AddressVerificationStatus>
  /** Date when verification was checked */
  verifiedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  zipcode?: Maybe<Scalars['String']['output']>
}

/** Attributes to create or update an address */
export type AddressInput = {
  cityName: Scalars['String']['input']
  line1: Scalars['String']['input']
  line2?: InputMaybe<Scalars['String']['input']>
  /** Instruction to verify the address */
  mustVerify?: InputMaybe<Scalars['Boolean']['input']>
  stateCode?: InputMaybe<Scalars['String']['input']>
  zipcode: Scalars['String']['input']
}

/** The verification status of an address */
export enum AddressVerificationStatus {
  Deliverable = 'deliverable',
  IncorrectOrMissingUnit = 'incorrect_or_missing_unit',
  Undeliverable = 'undeliverable',
  Unknown = 'unknown',
}

export type AliceCard = {
  __typename?: 'AliceCard'
  activatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** The ID of the card in the external system (e.g. Stripe, Synapse) */
  bankingSystemId?: Maybe<Scalars['String']['output']>
  cardType: AliceCardType
  createdAt: Scalars['ISO8601DateTime']['output']
  /** The ephemeral key object returned from the Stripe API */
  ephemeralKey?: Maybe<Scalars['JSON']['output']>
  /**
   * The ephemeral key secret used to display card details in a web application
   * @deprecated use ephemeralKey(nonce:) query and parse result
   */
  ephemeralKeySecret?: Maybe<Scalars['String']['output']>
  expiryDate: Scalars['FormattedDate']['output']
  id: Scalars['Int']['output']
  isConnectedToDigitalWallet: Scalars['Boolean']['output']
  issuingPlatform: AliceCardIssuingPlatform
  last4: Scalars['String']['output']
  reportedMissingAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  status: AliceCardStatus
  /** The wallets that the card is enrolled in */
  wallets?: Maybe<StripeWallets>
}

export type AliceCardEphemeralKeyArgs = {
  nonce?: InputMaybe<Scalars['String']['input']>
}

export type AliceCardEphemeralKeySecretArgs = {
  nonce: Scalars['String']['input']
}

export type AliceCardExpiryDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** Information about the employee's Alice Card(s) */
export type AliceCardAccount = {
  __typename?: 'AliceCardAccount'
  /** @deprecated use spendingConnectionInfo.aliceCard(id:) query */
  aliceCard?: Maybe<AliceCard>
  /** @deprecated only for Synapse cards - no longer used */
  aliceCardEnrollment?: Maybe<AliceCardEnrollment>
  aliceCards: Array<AliceCard>
  /** @deprecated use spendingConnectionInfo.balanceInfo */
  balanceMassTransit: Scalars['Int']['output']
  balanceMonthStart: Scalars['FormattedDate']['output']
  /** @deprecated use spendingConnectionInfo.balanceInfo */
  balanceParking: Scalars['Int']['output']
  cardProvisioningStatus: AliceCardProvisioningStatusInfo
  /** @deprecated use cardProvisioningStatus */
  cardTypesRequested?: Maybe<AliceCardType>
  id: Scalars['Int']['output']
  /** The name of the platform for the account */
  platform: AliceCardPlatform
  status: AliceCardAccountStatus
  /** @deprecated use spendingConnectionInfo.balanceInfo */
  usedMassTransit: Scalars['Int']['output']
  /** @deprecated use spendingConnectionInfo.balanceInfo */
  usedParking: Scalars['Int']['output']
}

/** Information about the employee's Alice Card(s) */
export type AliceCardAccountAliceCardArgs = {
  id: Scalars['Int']['input']
}

/** Information about the employee's Alice Card(s) */
export type AliceCardAccountBalanceMonthStartArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export enum AliceCardAccountStatus {
  Active = 'active',
  BalanceGated = 'balance_gated',
  Blocked = 'blocked',
  Inactive = 'inactive',
  Terminated = 'terminated',
}

export type AliceCardEnrollment = {
  __typename?: 'AliceCardEnrollment'
  /** Is the user enrolled right now */
  enrolled: Scalars['Boolean']['output']
  /** The date of initial enrollment */
  enrolledAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** The date when the the user opted out (or was opted out by admin) */
  optedOutAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** The date when the Terms of Service were accepted */
  tosAcceptedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/** Funding information for the parent object */
export type AliceCardFunding = {
  __typename?: 'AliceCardFunding'
  availablePaymentMethods?: Maybe<Array<PaymentMethod>>
  paymentMethodConnections?: Maybe<Array<PaymentMethodConnection>>
  status: AliceCardFundingStatus
  /** @deprecated no longer used */
  verificationUrl?: Maybe<Scalars['String']['output']>
}

/** Status of the card funding for the parent object */
export enum AliceCardFundingStatus {
  /** Blocked */
  Blocked = 'blocked',
  /** Connected */
  Connected = 'connected',
  /** Not connected */
  NotConnected = 'not_connected',
}

/** The issuing platform of an alice card. Eg 'stripe' or 'synapse' */
export enum AliceCardIssuingPlatform {
  Stripe = 'stripe',
  Synapse = 'synapse',
}

/** The status of the organization's Stripe Issuing integration */
export enum AliceCardIssuingStatus {
  Active = 'active',
  Deactivated = 'deactivated',
  /** allowed to set up Alice Card issuing, but doesn't have a company account */
  Enabled = 'enabled',
  NotSubmitted = 'not_submitted',
  Submitted = 'submitted',
}

export enum AliceCardKycStatus {
  /** Complete */
  Complete = 'complete',
  /** Not started */
  NotStarted = 'not_started',
  /** Pending */
  Pending = 'pending',
  /** Started */
  Started = 'started',
}

export enum AliceCardPlatform {
  Stripe = 'stripe',
  Synapse = 'synapse',
}

/** Organization-specific configuration for the Alice Card program */
export type AliceCardProgram = {
  __typename?: 'AliceCardProgram'
  /** Employees who have expressed interest in the Alice Card program - use pageInfo to get count */
  balanceGatedEmployees: EmployeeConnection
  /** Status of the organization's Stripe Issuing integration */
  cardIssuingStatus: AliceCardIssuingStatus
  companyAccount?: Maybe<StripeCompanyAccount>
  hasFundingConnected: Scalars['Boolean']['output']
  hasUnifiedBanking?: Maybe<Scalars['Boolean']['output']>
  kycStatus: AliceCardKycStatus
}

/** Organization-specific configuration for the Alice Card program */
export type AliceCardProgramBalanceGatedEmployeesArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export enum AliceCardProvisioningStatus {
  Provisioned = 'provisioned',
  Requested = 'requested',
}

export type AliceCardProvisioningStatusInfo = {
  __typename?: 'AliceCardProvisioningStatusInfo'
  instant?: Maybe<AliceCardProvisioningStatus>
  physical?: Maybe<AliceCardProvisioningStatus>
}

export enum AliceCardStatus {
  Active = 'active',
  Canceled = 'canceled',
  Inactive = 'inactive',
  Terminated = 'terminated',
}

export enum AliceCardType {
  Instant = 'instant',
  /** An instant card and a physical card */
  InstantAndPhysical = 'instant_and_physical',
  Physical = 'physical',
  /** A virtual card */
  Virtual = 'virtual',
}

/** Employee accounting ledger with balances for all benefits */
export type AliceLedger = {
  __typename?: 'AliceLedger'
  commuter?: Maybe<CommuterLedger>
  createdAt: Scalars['ISO8601DateTime']['output']
  dependentCare?: Maybe<DependentCareLedger>
  /** Estimated savings this year for current spending projections on all benefits */
  estimatedAnnualSavings: Scalars['Int']['output']
  /** Total savings for all benefits over the lifetime of the employee's account */
  estimatedLifetimeSavings: Scalars['Int']['output']
  healthcare?: Maybe<HealthcareLedger>
  id: Scalars['Int']['output']
  /** Paginated pay period records for the requested employee */
  payPeriodRecords: PayPeriodRecordConnection
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Employee accounting ledger with balances for all benefits */
export type AliceLedgerPayPeriodRecordsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type AnswerInputObject = {
  id?: InputMaybe<Scalars['Int']['input']>
  questionId?: InputMaybe<Scalars['Int']['input']>
  text: Scalars['AnswerTextUnion']['input']
}

export type Balance = {
  __typename?: 'Balance'
  remaining: Scalars['Int']['output']
  used: Scalars['Int']['output']
}

export type BalanceInfo = {
  __typename?: 'BalanceInfo'
  massTransit: Balance
  monthStart: Scalars['FormattedDate']['output']
  parking: Balance
}

export type BalanceInfoMonthStartArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** An object that contains a possible reason why a balance may be constrained */
export type BalanceLimitation = {
  __typename?: 'BalanceLimitation'
  contributionDelinquency?: Maybe<Scalars['ISO8601DateTime']['output']>
  employeeTermination?: Maybe<Scalars['ISO8601DateTime']['output']>
  employerOffboarding?: Maybe<Scalars['ISO8601DateTime']['output']>
  planYearEndDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  /** Amount of spend allowed for the benefit with respect to the spending period */
  spendingCap?: Maybe<Scalars['Int']['output']>
}

export enum BankCardType {
  Credit = 'credit',
  Debit = 'debit',
  Instant = 'instant',
  Omny = 'omny',
}

export enum BankableTypeEnum {
  Org = 'org',
  Paygroup = 'paygroup',
}

/** A client account that is held at a connected institution, including Alice Card */
export type BankingAccount = {
  __typename?: 'BankingAccount'
  accountType?: Maybe<BankingAccountType>
  /** The ID of the banking account in the billing system */
  billingSystemId?: Maybe<Scalars['String']['output']>
  /** The type of card, if known and applicable (will not be available for bank accounts) */
  cardType?: Maybe<BankCardType>
  error?: Maybe<Error>
  id: Scalars['Int']['output']
  last4?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  platform: BankingAccountPlatform
}

/** The platform that provides the account */
export enum BankingAccountPlatform {
  AliceCardStripe = 'alice_card_stripe',
  AliceCardSynapse = 'alice_card_synapse',
  Finicity = 'finicity',
  Offline = 'offline',
  Omny = 'omny',
  Plaid = 'plaid',
  Quovo = 'quovo',
  Stripe = 'stripe',
}

/** The type of account, if known - may be empty */
export enum BankingAccountType {
  Alice = 'alice',
  Banking = 'banking',
  Brokerage = 'brokerage',
  Credit = 'credit',
  Depository = 'depository',
  Insurance = 'insurance',
  Investment = 'investment',
  Loan = 'loan',
  Other = 'other',
  Unknown = 'unknown',
}

/** A user-specific connection to an institution that provides account and transaction information */
export type BankingConnection = {
  __typename?: 'BankingConnection'
  /** Phyical card shipment for this connection, if any (only OMNY) */
  activeCardShipment?: Maybe<CardShipment>
  /** user's accounts that are held at the connected institution */
  bankingAccounts: Array<BankingAccount>
  /** The ID of the connection */
  id: Scalars['Int']['output']
  institution?: Maybe<BankingInstitution>
  platform?: Maybe<BankingAccountPlatform>
  status: BankingConnectionStatus
}

export enum BankingConnectionPlatform {
  Finicity = 'finicity',
  Offline = 'offline',
  Omny = 'omny',
  Plaid = 'plaid',
  Quovo = 'quovo',
}

export enum BankingConnectionStatus {
  AliceDisconnected = 'alice_disconnected',
  Connected = 'connected',
  ConnectionError = 'connection_error',
  CustomerDisconnected = 'customer_disconnected',
  Disconnecting = 'disconnecting',
  Pending = 'pending',
  SystemDisconnected = 'system_disconnected',
}

/** A bank, or at least something that aggregates accounts */
export type BankingInstitution = {
  __typename?: 'BankingInstitution'
  /** Internal BankingInstitution ID */
  id: Scalars['Int']['output']
  /** The name of the connected institution */
  name?: Maybe<Scalars['String']['output']>
  /** The platform that currently provides the connection */
  platform: BankingInstitutionPlaform
  /** The institution identifier used by the preferred platform */
  platformId?: Maybe<Scalars['String']['output']>
  /** The platform that we prefer to use for the connection - reconnect should use this if possible */
  preferredPlatform?: Maybe<BankingInstitutionPlaform>
  /** The ID of the unified institution */
  unifiedInstitutionId?: Maybe<Scalars['Int']['output']>
}

export enum BankingInstitutionPlaform {
  Finicity = 'finicity',
  Plaid = 'plaid',
  Quovo = 'quovo',
}

/** A record of a banking transaction */
export type BankingTransaction = {
  __typename?: 'BankingTransaction'
  account: FundingAccount
  amount: Scalars['Int']['output']
  date: Scalars['FormattedDate']['output']
  estimatedSavings: Scalars['Int']['output']
  id: Scalars['ID']['output']
  /** Just the merchant name, might not always be available */
  merchantName?: Maybe<Scalars['String']['output']>
  /** The merchant name and some other text (including junky characters sometimes) */
  name: Scalars['String']['output']
  reimbursementClaim?: Maybe<ReimbursementClaim>
  reimbursementStatus?: Maybe<ReimbursementStatus>
  spendEligibility: SpendEligibility
  subsidizedAmount: Scalars['Int']['output']
}

/** A record of a banking transaction */
export type BankingTransactionDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for BankingTransaction. */
export type BankingTransactionConnection = {
  __typename?: 'BankingTransactionConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BankingTransactionEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BankingTransaction>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type BankingTransactionEdge = {
  __typename?: 'BankingTransactionEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<BankingTransaction>
}

export enum BenefitType {
  DependentCareFsa = 'dependent_care_fsa',
  HealthcareFsa = 'healthcare_fsa',
  HealthcareHsa = 'healthcare_hsa',
  LimitedPurposeFsa = 'limited_purpose_fsa',
  MassTransit = 'mass_transit',
  Parking = 'parking',
}

export enum BillableType {
  Org = 'org',
  Paygroup = 'paygroup',
}

/** Billing information for a billable entity, e.g. organization or employer */
export type Billing = {
  __typename?: 'Billing'
  billingSource?: Maybe<BillingSource>
  /** The ID of the billable entity in the billing system */
  billingSystemId?: Maybe<Scalars['String']['output']>
  billingWorkspace: BillingWorkspace
  hasBillingConnected: Scalars['Boolean']['output']
  hasUnifiedBilling: Scalars['Boolean']['output']
  invoice?: Maybe<Invoice>
  invoicesConnection: InvoiceConnection
}

/** Billing information for a billable entity, e.g. organization or employer */
export type BillingInvoiceArgs = {
  id: Scalars['Int']['input']
}

/** Billing information for a billable entity, e.g. organization or employer */
export type BillingInvoicesConnectionArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  employerId?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

/** The source of billing funds for a billable entity */
export type BillingSource = BankingAccount | PaymentCard

export enum BillingWorkspace {
  Pretaxhero = 'pretaxhero',
  Thisisalice = 'thisisalice',
}

/** The type of card request */
export enum CardRequestTypeEnum {
  Instant = 'instant',
  InstantAndPhysical = 'instant_and_physical',
  Physical = 'physical',
}

/** A shipment of a physical card to a user */
export type CardShipment = {
  __typename?: 'CardShipment'
  id: Scalars['Int']['output']
  platform: BankingAccountPlatform
  reportedMissingAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  shippedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
}

export enum CardShipmentStatus {
  Missing = 'missing',
  Received = 'received',
}

export type CashPaymentAccount = {
  __typename?: 'CashPaymentAccount'
  id: Scalars['ID']['output']
}

/** A representation of a time duration with a fixed starting datetime and ending datetime */
export type ClosedTimePeriod = {
  __typename?: 'ClosedTimePeriod'
  endDate: Scalars['ISO8601DateTime']['output']
  startDate: Scalars['ISO8601DateTime']['output']
}

/** Employee accounting ledger with balances for dependent care enrollment(s) */
export type CommuterLedger = EnrollmentLedger & {
  __typename?: 'CommuterLedger'
  contributions: ContributionBreakdown
  createdAt: Scalars['ISO8601DateTime']['output']
  current?: Maybe<LegerBalance>
  deductions: DeductionBreakdown
  enrollments: Array<Enrollment>
  id: Scalars['Int']['output']
  massTransit?: Maybe<MassTransitLedger>
  parking?: Maybe<ParkingLedger>
  reimbursement: ReimbursementSummary
  runout?: Maybe<LegerBalance>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Autogenerated input type of ConfirmTransactions */
export type ConfirmTransactionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Confirm (true) or reject/disconfirm (false) the specified transactions */
  isConfirmed: Scalars['Boolean']['input']
  /** The IDs of the transactions to confirm or reject */
  transactionIds: Array<Scalars['ID']['input']>
}

/** Autogenerated return type of ConfirmTransactions. */
export type ConfirmTransactionsPayload = {
  __typename?: 'ConfirmTransactionsPayload'
  bankingTransactions?: Maybe<Array<BankingTransaction>>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
}

/** The date and value for user confirmation of spend eligibility */
export type ConfirmationStatus = {
  __typename?: 'ConfirmationStatus'
  updatedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  value: ConfirmationStatusValue
}

export enum ConfirmationStatusValue {
  /** Confirmed eligible */
  Confirmed = 'confirmed',
  /** Eligibility rejected after being confirmed eligible */
  Disconfirmed = 'disconfirmed',
  /** Eligibility has been rejected by user */
  Rejected = 'rejected',
  /** No confirmation has been supplied */
  Unconfirmed = 'unconfirmed',
}

/** Autogenerated input type of ConnectOMNYCard */
export type ConnectOmnyCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

/** Autogenerated return type of ConnectOMNYCard. */
export type ConnectOmnyCardPayload = {
  __typename?: 'ConnectOMNYCardPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  connection?: Maybe<BankingConnection>
  inputErrors: Array<InputError>
}

export type ContributionBreakdown = {
  __typename?: 'ContributionBreakdown'
  current: Scalars['Int']['output']
  limit: Scalars['Int']['output']
  remaining: Scalars['Int']['output']
  upcoming?: Maybe<Scalars['Int']['output']>
}

/** Autogenerated input type of CreateACHPaymentSource */
export type CreateAchPaymentSourceInput = {
  acceptedMandate: Scalars['Boolean']['input']
  accountNumber: Scalars['String']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ownerId: Scalars['Int']['input']
  ownerType: BankableTypeEnum
  routingNumber: Scalars['String']['input']
}

/** Autogenerated return type of CreateACHPaymentSource. */
export type CreateAchPaymentSourcePayload = {
  __typename?: 'CreateACHPaymentSourcePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  paymentMethodConnection?: Maybe<PaymentMethodConnection>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateAccountSession */
export type CreateAccountSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of CreateAccountSession. */
export type CreateAccountSessionPayload = {
  __typename?: 'CreateAccountSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  clientSecret?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of CreateAdminKYCLink */
export type CreateAdminKycLinkInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of CreateAdminKYCLink. */
export type CreateAdminKycLinkPayload = {
  __typename?: 'CreateAdminKYCLinkPayload'
  adminKycLink?: Maybe<Scalars['String']['output']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of CreateBillingSetupIntent */
export type CreateBillingSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ownerId: Scalars['Int']['input']
  ownerType: BillableType
  /** Pass a Stripe system ID (e.g. pm_123) of existing US bank account payment method if you want to re-affirm the ACH mandate */
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateBillingSetupIntent. */
export type CreateBillingSetupIntentPayload = {
  __typename?: 'CreateBillingSetupIntentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** used by Stripe.js widget to create a SetupIntent to connect billing */
  clientSecret?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  /** Customer ID for the Stripe account, persisted as billable.billing_system_id */
  stripeAccount?: Maybe<Scalars['String']['output']>
}

/** Autogenerated input type of CreateEmployee */
export type CreateEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  /** The ID of the employer to create the employee for, default to the current user's employer */
  employerId?: InputMaybe<Scalars['Int']['input']>
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  role: EmployeeRole
}

/** Autogenerated return type of CreateEmployee. */
export type CreateEmployeePayload = {
  __typename?: 'CreateEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee?: Maybe<Employee>
  inputErrors: Array<InputError>
}

/** Autogenerated input type of CreateFinchConnection */
export type CreateFinchConnectionInput = {
  /** Authorization code returned by Finch Connect */
  authorizationCode: Scalars['String']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Authorization code limited to these employers */
  employerId?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated return type of CreateFinchConnection. */
export type CreateFinchConnectionPayload = {
  __typename?: 'CreateFinchConnectionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of CreateFinicityConnectUrl */
export type CreateFinicityConnectUrlInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeId: Scalars['Int']['input']
  institutionPlatformId: Scalars['String']['input']
}

/** Autogenerated return type of CreateFinicityConnectUrl. */
export type CreateFinicityConnectUrlPayload = {
  __typename?: 'CreateFinicityConnectUrlPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  connectUrl: Scalars['String']['output']
  employee: Employee
  inputErrors: Array<InputError>
}

/** Autogenerated input type of CreateFinicityConnection */
export type CreateFinicityConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeId: Scalars['Int']['input']
  institutionPlatformId: Scalars['String']['input']
}

/** Autogenerated return type of CreateFinicityConnection. */
export type CreateFinicityConnectionPayload = {
  __typename?: 'CreateFinicityConnectionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee: Employee
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateFormSubmission */
export type CreateFormSubmissionInput = {
  /** Array of [questionId, id, text] tuples */
  answers: Array<AnswerInputObject>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Status of the submission */
  status?: InputMaybe<FormSubmissionStatus>
  /** ID of the subject the form concerns */
  subjectId: Scalars['Int']['input']
  /** Type of the subject (e.g., 'User', 'Organization') */
  subjectType: SubmissionRelation
  /** ID of the form template */
  templateId: Scalars['Int']['input']
}

/** Autogenerated return type of CreateFormSubmission. */
export type CreateFormSubmissionPayload = {
  __typename?: 'CreateFormSubmissionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  submissionId: Scalars['Int']['output']
}

/** Autogenerated input type of CreateHostedLinkSession */
export type CreateHostedLinkSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeId: Scalars['Int']['input']
  existingConnectionId?: InputMaybe<Scalars['Int']['input']>
  /** The URL to redirect to after the user completes the session */
  returnUri: Scalars['String']['input']
}

/** Autogenerated return type of CreateHostedLinkSession. */
export type CreateHostedLinkSessionPayload = {
  __typename?: 'CreateHostedLinkSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee: Employee
  hostedLinkUrl: Scalars['String']['output']
  inputErrors: Array<InputError>
}

/** Autogenerated input type of CreateIssuingSetupIntent */
export type CreateIssuingSetupIntentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  stripeCompanyAccountId: Scalars['Int']['input']
  /** Pass a Stripe system ID (e.g. pm_123) of existing payment method if you want to re-affirm the ACH mandate */
  stripePaymentMethodId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreateIssuingSetupIntent. */
export type CreateIssuingSetupIntentPayload = {
  __typename?: 'CreateIssuingSetupIntentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  /** used by Stripe.js widget to create a SetupIntent */
  clientSecret?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
}

/** Autogenerated input type of CreateLinkSession */
export type CreateLinkSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeId: Scalars['Int']['input']
  existingConnectionId?: InputMaybe<Scalars['Int']['input']>
}

/** Autogenerated return type of CreateLinkSession. */
export type CreateLinkSessionPayload = {
  __typename?: 'CreateLinkSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee: Employee
  inputErrors: Array<InputError>
  linkToken: Scalars['String']['output']
}

/** Autogenerated input type of CreateOrgFromProvisional */
export type CreateOrgFromProvisionalInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  provisionalOrgId: Scalars['Int']['input']
}

/** Autogenerated return type of CreateOrgFromProvisional. */
export type CreateOrgFromProvisionalPayload = {
  __typename?: 'CreateOrgFromProvisionalPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
  provisionalOrganization?: Maybe<ProvisionalOrganization>
}

/** Autogenerated input type of CreatePaymentMethodConnection */
export type CreatePaymentMethodConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the object to fund */
  ownerId: Scalars['Int']['input']
  /** The type of object to fund */
  ownerType: BankableTypeEnum
  /** The existing PaymentMethod ID to link to the owner */
  paymentMethodId: Scalars['Int']['input']
}

/** Autogenerated return type of CreatePaymentMethodConnection. */
export type CreatePaymentMethodConnectionPayload = {
  __typename?: 'CreatePaymentMethodConnectionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of CreatePaymentMethod */
export type CreatePaymentMethodInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The ID of the object to fund */
  ownerId: Scalars['Int']['input']
  /** The type of object to fund */
  ownerType: BankableTypeEnum
  /** The Stripe ID of the payment method to connect */
  paymentMethodId: Scalars['String']['input']
  routingNumber?: InputMaybe<Scalars['String']['input']>
  /** The URL to verify the payment method, if necessary */
  verificationUrl?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of CreatePaymentMethod. */
export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of CreatePhoneLoginCode */
export type CreatePhoneLoginCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The user's mobile phone number */
  tel: Scalars['String']['input']
}

/** Autogenerated return type of CreatePhoneLoginCode. */
export type CreatePhoneLoginCodePayload = {
  __typename?: 'CreatePhoneLoginCodePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  /** @deprecated This field is no longer required */
  needsPassword: Scalars['Boolean']['output']
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreatePhoneVerificationCode */
export type CreatePhoneVerificationCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The user's mobile phone number */
  tel: Scalars['String']['input']
}

/** Autogenerated return type of CreatePhoneVerificationCode. */
export type CreatePhoneVerificationCodePayload = {
  __typename?: 'CreatePhoneVerificationCodePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of CreateWorkLocation */
export type CreateWorkLocationInput = {
  /** The address of the workLocation */
  address?: InputMaybe<AddressInput>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The latitude of the workLocation */
  latitude?: InputMaybe<Scalars['Float']['input']>
  /** The longitude of the workLocation */
  longitude?: InputMaybe<Scalars['Float']['input']>
  /** The name of the workLocation */
  name: Scalars['String']['input']
  /** A nickname for the workLocation set by an admin */
  nickname?: InputMaybe<Scalars['String']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of CreateWorkLocation. */
export type CreateWorkLocationPayload = {
  __typename?: 'CreateWorkLocationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
  workLocation: WorkLocation
}

/** Employee accounting ledger with balances for DCFSA enrollment(s) */
export type DcfsaLedger = EnrollmentLedger & {
  __typename?: 'DCFSALedger'
  contributions: ContributionBreakdown
  current?: Maybe<LegerBalance>
  deductions: DeductionBreakdown
  enrollments: Array<Enrollment>
  reimbursement: ReimbursementSummary
  runout?: Maybe<LegerBalance>
}

/** A record of a payroll deduction */
export type Deduction = {
  __typename?: 'Deduction'
  amount: Scalars['Int']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  timestamp: Scalars['ISO8601DateTime']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type DeductionBreakdown = {
  __typename?: 'DeductionBreakdown'
  actual: Array<Deduction>
  projected: Array<Deduction>
}

/** Autogenerated input type of DeleteBillingSource */
export type DeleteBillingSourceInput = {
  billingSourceId: Scalars['Int']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ownerId: Scalars['Int']['input']
  ownerType: BillableType
}

/** Autogenerated return type of DeleteBillingSource. */
export type DeleteBillingSourcePayload = {
  __typename?: 'DeleteBillingSourcePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success?: Maybe<Scalars['Boolean']['output']>
}

/** Autogenerated input type of DeleteWorkLocation */
export type DeleteWorkLocationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The id of the work location to delete */
  workLocationId: Scalars['Int']['input']
}

/** Autogenerated return type of DeleteWorkLocation. */
export type DeleteWorkLocationPayload = {
  __typename?: 'DeleteWorkLocationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  workLocationId: Scalars['Int']['output']
}

/** Employee accounting ledger with balances for dependent care enrollment(s) */
export type DependentCareLedger = EnrollmentLedger & {
  __typename?: 'DependentCareLedger'
  contributions: ContributionBreakdown
  createdAt: Scalars['ISO8601DateTime']['output']
  current?: Maybe<LegerBalance>
  dcfsa?: Maybe<DcfsaLedger>
  deductions: DeductionBreakdown
  enrollments: Array<Enrollment>
  id: Scalars['Int']['output']
  reimbursement: ReimbursementSummary
  runout?: Maybe<LegerBalance>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Autogenerated input type of DisconnectConnection */
export type DisconnectConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  connectionId: Scalars['Int']['input']
}

/** Autogenerated return type of DisconnectConnection. */
export type DisconnectConnectionPayload = {
  __typename?: 'DisconnectConnectionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  connection?: Maybe<BankingConnection>
  inputErrors: Array<InputError>
}

export type Employee = {
  __typename?: 'Employee'
  employer: Employer
  /** @deprecated use paygroupInfo instead */
  employerInfo: EmployerInfo
  employmentStartDate?: Maybe<Scalars['FormattedDate']['output']>
  enrollmentInfo: EmployeeEnrollment
  id: Scalars['Int']['output']
  isCanonical: Scalars['Boolean']['output']
  /** @deprecated use role instead */
  isEmployerAdmin: Scalars['Boolean']['output']
  /** @deprecated use role instead */
  isOrganizationAdmin: Scalars['Boolean']['output']
  ledger?: Maybe<AliceLedger>
  mailingAddress?: Maybe<AddressDisplay>
  paygroupInfo: EmployerInfo
  payrollAddress?: Maybe<AddressDisplay>
  role: EmployeeRole
  spendingConnectionInfo: SpendingConnectionInfo
  spendingInfo: SpendingInfo
  terminationDate?: Maybe<Scalars['FormattedDate']['output']>
  tosAcceptedAt?: Maybe<Scalars['FormattedDate']['output']>
  user: User
}

export type EmployeeEmploymentStartDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export type EmployeeTerminationDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export type EmployeeTosAcceptedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** The status of an employee's account */
export enum EmployeeAccountStatus {
  Enrolled = 'enrolled',
  Ineligible = 'ineligible',
  NotEnrolled = 'not_enrolled',
  OptedOut = 'opted_out',
  Suspended = 'suspended',
  Terminated = 'terminated',
}

/** An employee account status that can be set by an admin, overwriting the employee's own status */
export enum EmployeeAccountStatusOverride {
  OptedOut = 'opted_out',
  Suspended = 'suspended',
}

/** The connection type for Employee. */
export type EmployeeConnection = {
  __typename?: 'EmployeeConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EmployeeEdge>>>
  employedCount: Scalars['Int']['output']
  enrolledCount: Scalars['Int']['output']
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Employee>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
  reachableCount: Scalars['Int']['output']
  totalCount: Scalars['Int']['output']
  totalDeductions: Scalars['Float']['output']
  totalSavings: Scalars['Float']['output']
}

/** An edge in a connection. */
export type EmployeeEdge = {
  __typename?: 'EmployeeEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Employee>
}

/** data related to the status of an employee's enrollment flow */
export type EmployeeEnrollment = {
  __typename?: 'EmployeeEnrollment'
  accountStatus: EmployeeAccountStatus
  /** Whether the employee may use re-enrollment flow instead of full enrollment */
  canReenroll: Scalars['Boolean']['output']
  /** The reason the employee cannot enroll, if any */
  enrollmentBlocker?: Maybe<EmployeeEnrollmentBlocker>
  enrollmentDate?: Maybe<Scalars['FormattedDate']['output']>
  /** The reason the employee cannot register, if any */
  registrationBlocker?: Maybe<EmployeeRegistrationBlocker>
  requirements: EmployeeEnrollmentRequirements
  unenrollmentDate?: Maybe<Scalars['FormattedDate']['output']>
}

/** data related to the status of an employee's enrollment flow */
export type EmployeeEnrollmentEnrollmentDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** data related to the status of an employee's enrollment flow */
export type EmployeeEnrollmentUnenrollmentDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** The reason an employee cannot enroll */
export enum EmployeeEnrollmentBlocker {
  DataRemovalRequest = 'data_removal_request',
  Enrolled = 'enrolled',
  InactiveEmployer = 'inactive_employer',
  Ineligible = 'ineligible',
  MissingCity = 'missing_city',
  NotEnrolledInBenefit = 'not_enrolled_in_benefit',
  OtherAccount = 'other_account',
  Suspended = 'suspended',
  Terminated = 'terminated',
}

/** The status of an employee's enrollment requirement */
export enum EmployeeEnrollmentRequirementStatus {
  /** Blocked */
  Blocked = 'blocked',
  /** Complete */
  Complete = 'complete',
  /** Incomplete */
  Incomplete = 'incomplete',
  /** Pending */
  Pending = 'pending',
}

/** The status of an employee's enrollment requirements */
export type EmployeeEnrollmentRequirements = {
  __typename?: 'EmployeeEnrollmentRequirements'
  /** The status of the employee's employment start date requirement */
  employmentStartDate: EmployeeEnrollmentRequirementStatus
  /** The status of the employee's phone verification requirement */
  phoneVerification: EmployeeEnrollmentRequirementStatus
  /** The status of the employee's registration requirement */
  registration: EmployeeEnrollmentRequirementStatus
  /** The status of the employee's spending connection requirement */
  spendingConnection: EmployeeEnrollmentRequirementStatus
}

/** Plaid-specific state related to an employee's spending connections */
export type EmployeePlaid = {
  __typename?: 'EmployeePlaid'
  linkSessions?: Maybe<Array<PlaidLinkSession>>
}

/** The reason an employee cannot register */
export enum EmployeeRegistrationBlocker {
  DataRemovalRequest = 'data_removal_request',
  InactiveEmployer = 'inactive_employer',
  Ineligible = 'ineligible',
  MissingCity = 'missing_city',
  OtherAccount = 'other_account',
  Suspended = 'suspended',
  Terminated = 'terminated',
}

/** The name of a role that an employee may have */
export enum EmployeeRole {
  /** @deprecated Use organization_admin instead */
  EmployerAdmin = 'employer_admin',
  NonAdmin = 'non_admin',
  OrganizationAdmin = 'organization_admin',
}

export type Employer = {
  __typename?: 'Employer'
  aliceCardFunding: AliceCardFunding
  billing?: Maybe<Billing>
  employees: EmployeeConnection
  id: Scalars['Int']['output']
  inviteCode: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  marketingFriendlyName?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  offboardingDate?: Maybe<Scalars['FormattedDate']['output']>
  /** Status of employer onboarding */
  onboardingStatus?: Maybe<EmployerOnboardingStatus>
  organization?: Maybe<Organization>
  payPeriods: PayPeriodConnection
  paygroupConfig?: Maybe<PaygroupConfig>
}

export type EmployerEmployeesArgs = {
  accountStatuses?: InputMaybe<Array<EmployeeAccountStatus>>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  employerId?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<EmployeeRole>
  searchTerms?: InputMaybe<Array<Scalars['String']['input']>>
}

export type EmployerOffboardingDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export type EmployerPayPeriodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  employerId?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type EmployerInfo = {
  __typename?: 'EmployerInfo'
  id: Scalars['Int']['output']
  inviteCode: Scalars['String']['output']
  marketingFriendlyName?: Maybe<Scalars['String']['output']>
  name?: Maybe<Scalars['String']['output']>
  offboardingDate?: Maybe<Scalars['FormattedDate']['output']>
}

export type EmployerInfoOffboardingDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** Status of employer onboarding */
export enum EmployerOnboardingStatus {
  BillingCompleted = 'billing_completed',
  DetailsCollected = 'details_collected',
  HsaAcctCompleted = 'hsa_acct_completed',
  Onboarded = 'onboarded',
  PlansSelected = 'plans_selected',
  PlansSigned = 'plans_signed',
  Started = 'started',
}

export type Enrollment = {
  __typename?: 'Enrollment'
  balance: LegerBalance
  createdAt: Scalars['ISO8601DateTime']['output']
  election: Scalars['Int']['output']
  id: Scalars['Int']['output']
  inboundRollover: Scalars['Int']['output']
  outboundRollover: Scalars['Int']['output']
  planYear: PlanYear
  status?: Maybe<EnrollmentStatus>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Employer information for enrollment landing page */
export type EnrollmentEmployer = {
  __typename?: 'EnrollmentEmployer'
  aliceCardFundingStatus: AliceCardFundingStatus
  enrolledCount: Scalars['Int']['output']
  id: Scalars['Int']['output']
  inviteCode: Scalars['String']['output']
  isActive: Scalars['Boolean']['output']
  marketingFriendlyName?: Maybe<Scalars['String']['output']>
}

export type EnrollmentFlyer = {
  __typename?: 'EnrollmentFlyer'
  breakroom?: Maybe<LangUrl>
  businessCard?: Maybe<LangUrl>
  faqs?: Maybe<LangUrl>
  intro?: Maybe<LangUrl>
}

export type EnrollmentLedger = {
  contributions: ContributionBreakdown
  current?: Maybe<LegerBalance>
  deductions: DeductionBreakdown
  enrollments: Array<Enrollment>
  reimbursement: ReimbursementSummary
  runout?: Maybe<LegerBalance>
}

/** Our full suite of enrollment materials for a given location */
export type EnrollmentMaterials = {
  __typename?: 'EnrollmentMaterials'
  enrollmentFlyers?: Maybe<EnrollmentFlyer>
  zippedMaterialsUrl?: Maybe<Scalars['String']['output']>
}

export enum EnrollmentStatus {
  BecameIneligible = 'became_ineligible',
  Defaulted = 'defaulted',
  Enrolled = 'enrolled',
  Ineligible = 'ineligible',
  OptedOut = 'opted_out',
  ReviewedIneligible = 'reviewed_ineligible',
  Unopened = 'unopened',
  Unverified = 'unverified',
  Verified = 'verified',
}

/** An error object with optional code and message */
export type Error = {
  __typename?: 'Error'
  /** Internal only, should not be surfaced to the user. */
  code?: Maybe<Scalars['String']['output']>
  /** Potentially user-facing, so should be user-friendly and actionable. */
  message?: Maybe<Scalars['String']['output']>
}

/** Outstanding access requirements for particular features - nil if the user has full access */
export type FeatureAccess = {
  __typename?: 'FeatureAccess'
  /** The access requirement for the organization admin dashboard */
  orgAdminDashboard: AccessRequirementEnum
}

export type FinchConnection = {
  __typename?: 'FinchConnection'
  accountId: Scalars['String']['output']
  clientType: Scalars['String']['output']
  companyId: Scalars['String']['output']
  connectionType: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  isActive: Scalars['Boolean']['output']
  products?: Maybe<Array<Scalars['String']['output']>>
  providerId: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export enum FormSubmissionStatus {
  Completed = 'completed',
  InProgress = 'in_progress',
  NotStarted = 'not_started',
}

export type FormsAnswer = {
  __typename?: 'FormsAnswer'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  questionId: Scalars['Int']['output']
  submissionId: Scalars['Int']['output']
  text?: Maybe<Scalars['JSON']['output']>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type FormsQuestion = {
  __typename?: 'FormsQuestion'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  options?: Maybe<Array<Scalars['String']['output']>>
  placeholder?: Maybe<Scalars['String']['output']>
  questionType: QuestionType
  subtitle?: Maybe<Scalars['String']['output']>
  text: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type FormsSubmission = {
  __typename?: 'FormsSubmission'
  answers: Array<FormsAnswer>
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  ownerId?: Maybe<Scalars['Int']['output']>
  ownerType?: Maybe<Scalars['String']['output']>
  questions: Array<FormsQuestion>
  status?: Maybe<Scalars['String']['output']>
  subjectId: Scalars['Int']['output']
  subjectType: Scalars['String']['output']
  template: FormsTemplate
  templateId: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type FormsTemplate = {
  __typename?: 'FormsTemplate'
  active: Scalars['Boolean']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  displayOrder: Array<Scalars['Int']['output']>
  id: Scalars['Int']['output']
  questions: Array<FormsQuestion>
  templateType: Scalars['String']['output']
  templateVersion: Scalars['ISO8601DateTime']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export type FundingAccount = BankingAccount | CashPaymentAccount

/** Employee accounting ledger with balances for HFSA enrollment(s) */
export type HfsaLedger = EnrollmentLedger & {
  __typename?: 'HFSALedger'
  contributions: ContributionBreakdown
  current?: Maybe<LegerBalance>
  deductions: DeductionBreakdown
  enrollments: Array<Enrollment>
  reimbursement: ReimbursementSummary
  runout?: Maybe<LegerBalance>
}

/** Employee accounting ledger with balances for HSA enrollment(s) */
export type HsaLedger = EnrollmentLedger & {
  __typename?: 'HSALedger'
  contributions: ContributionBreakdown
  current?: Maybe<LegerBalance>
  deductions: DeductionBreakdown
  enrollments: Array<Enrollment>
  reimbursement: ReimbursementSummary
  runout?: Maybe<LegerBalance>
  spendableAsYouGo: LegerBalance
  spendableFromBalance: LegerBalance
}

/** Employee accounting ledger with balances for healtcare enrollment(s) */
export type HealthcareLedger = {
  __typename?: 'HealthcareLedger'
  createdAt: Scalars['ISO8601DateTime']['output']
  current: LegerBalance
  hfsa?: Maybe<HfsaLedger>
  hsa?: Maybe<HsaLedger>
  id: Scalars['Int']['output']
  runout?: Maybe<LegerBalance>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Image metadata, including host URL */
export type Image = {
  __typename?: 'Image'
  thumbnailUrl?: Maybe<Scalars['String']['output']>
  url?: Maybe<Scalars['String']['output']>
}

/** A user-readable error */
export type InputError = {
  __typename?: 'InputError'
  /** Which top-level input argument triggered this error */
  argument: Scalars['String']['output']
  /** A description of the error */
  message: Scalars['String']['output']
}

export type Invoice = {
  __typename?: 'Invoice'
  /**
   * Alice share
   * @deprecated use feeAmount instead
   */
  amount: Scalars['Int']['output']
  /** Date and time the invoice should have been run, or will be run (may be in the future) */
  billDate: Scalars['ISO8601DateTime']['output']
  /** Date and time the invoice was created */
  createdAt: Scalars['ISO8601DateTime']['output']
  /** End date of the lastest invoice item pay period */
  endDate?: Maybe<Scalars['FormattedDate']['output']>
  /** Total fee for services provided by Alice */
  feeAmount: Scalars['Int']['output']
  /** Gross fee for services provided by Alice, before adjustments, discounts, and credit balance is applied */
  grossAmount: Scalars['Int']['output']
  id: Scalars['Int']['output']
  /** Is the invoice overdue? */
  isOverdue: Scalars['Boolean']['output']
  /** List of invoice items */
  items: Array<InvoiceItem>
  /** Name of the owner - org or employer */
  ownerName?: Maybe<Scalars['String']['output']>
  /** Amount already paid */
  paidAmount: Scalars['Int']['output']
  /** Date and time the invoice was paid */
  paidDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  pricingAdjustments: Array<PricingAdjustment>
  /** Invoice refernece number */
  reference?: Maybe<Scalars['String']['output']>
  /** Start date of the earliest invoice item pay period */
  startDate?: Maybe<Scalars['FormattedDate']['output']>
  /** Status of the invoice */
  status: InvoiceStatus
}

export type InvoiceEndDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export type InvoiceStartDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for Invoice. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Invoice>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>
}

export type InvoiceItem = {
  __typename?: 'InvoiceItem'
  /**
   * Alice share
   * @deprecated use feeAmount instead
   */
  amount: Scalars['Int']['output']
  /** Invoice */
  description?: Maybe<Scalars['String']['output']>
  employeeDeductionTotal: Scalars['Int']['output']
  /** Fee for services provided by Alice */
  feeAmount: Scalars['Int']['output']
  id: Scalars['Int']['output']
}

/** The status of the invoice */
export enum InvoiceStatus {
  /** Autopay failed */
  AutopayFailed = 'autopay_failed',
  /** Failed */
  Failed = 'failed',
  /** Paid */
  Paid = 'paid',
  /** Pending */
  Pending = 'pending',
  /** Refunded */
  Refunded = 'refunded',
  /** Uncollectible */
  Uncollectible = 'uncollectible',
  /** Voided */
  Voided = 'voided',
}

export type LangUrl = {
  __typename?: 'LangUrl'
  enUrl?: Maybe<Scalars['String']['output']>
  esUrl?: Maybe<Scalars['String']['output']>
}

/** The language preference for a user */
export enum LanguagePreference {
  EnUs = 'en_us',
  Es = 'es',
}

/** An account balance amount with usage limitation information */
export type LegerBalance = {
  __typename?: 'LegerBalance'
  /** The available amount to spend with respect to the spending period */
  available: Scalars['Int']['output']
  limitationReason?: Maybe<BalanceLimitation>
  spendingPeriod: TimePeriod
  /** The sum of spending with respect to the spending period */
  spentSoFar: Scalars['Int']['output']
  substantiationDueDate?: Maybe<Scalars['ISO8601DateTime']['output']>
}

/** Autogenerated input type of LoginByPhoneCode */
export type LoginByPhoneCodeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The login code */
  code: Scalars['String']['input']
  /** The user's mobile phone number */
  tel: Scalars['String']['input']
}

/** Autogenerated return type of LoginByPhoneCode. */
export type LoginByPhoneCodePayload = {
  __typename?: 'LoginByPhoneCodePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  errorCode?: Maybe<TelLoginErrorCode>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of Logout */
export type LogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of Logout. */
export type LogoutPayload = {
  __typename?: 'LogoutPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Employee accounting ledger for the current year's mass transit benefit */
export type MassTransitLedger = {
  __typename?: 'MassTransitLedger'
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Balance for current month's spend. Returns the month of whatever day it is today */
  current?: Maybe<LegerBalance>
  id: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** YTD summary of spend for mass_transit benefits */
  yearToDate?: Maybe<LegerBalance>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Confirm or reject transactions for an employee */
  confirmTransactions?: Maybe<ConfirmTransactionsPayload>
  /** Create an account session in Stripe and return client secret */
  createAccountSession?: Maybe<CreateAccountSessionPayload>
  /** Create a link to Stripe's KYC flow for an organization */
  createAdminKycLink?: Maybe<CreateAdminKycLinkPayload>
  /** Save ACH numbers for later payment method creation - may only be used before KYB is complete */
  createAliceCardAchPaymentSource?: Maybe<CreateAchPaymentSourcePayload>
  /** Create and connect a new payment method to fund the specified object */
  createAliceCardPaymentMethod?: Maybe<CreatePaymentMethodPayload>
  /** Connect an existing payment method to fund the specified owner */
  createAliceCardPaymentMethodConnection?: Maybe<CreatePaymentMethodConnectionPayload>
  /** Create a Stripe setup intent to connect a billing source */
  createBillingSetupIntent?: Maybe<CreateBillingSetupIntentPayload>
  /** Create an employee */
  createEmployee?: Maybe<CreateEmployeePayload>
  /** Submit Finch authorization code to create a new connection */
  createFinchConnection?: Maybe<CreateFinchConnectionPayload>
  /** Create a Finicity Connect link */
  createFinicityConnectUrl?: Maybe<CreateFinicityConnectUrlPayload>
  /** Create a Finicity connection after a successful Finicity Connect session */
  createFinicityConnection?: Maybe<CreateFinicityConnectionPayload>
  /** Submit a form with answers */
  createFormSubmission?: Maybe<CreateFormSubmissionPayload>
  /** Create a hosted Plaid link session */
  createHostedLinkSession?: Maybe<CreateHostedLinkSessionPayload>
  /** Create a Stripe setup intent to connect a cards bank account */
  createIssuingSetupIntent?: Maybe<CreateIssuingSetupIntentPayload>
  /** Create a Plaid Link token for native app Link flow */
  createLinkSession?: Maybe<CreateLinkSessionPayload>
  /** Create an organization from an existing provisional organization */
  createOrgFromProvisional?: Maybe<CreateOrgFromProvisionalPayload>
  /** Request a phone login code */
  createPhoneLoginCode?: Maybe<CreatePhoneLoginCodePayload>
  /** Request a phone verification code */
  createPhoneVerificationCode?: Maybe<CreatePhoneVerificationCodePayload>
  /** Create a new workLocation for an organization */
  createWorkLocation?: Maybe<CreateWorkLocationPayload>
  /** Remove a billing source from an organization or employer */
  deleteBillingSource?: Maybe<DeleteBillingSourcePayload>
  /** Delete an employer work location - probably not something a user should be doing too often */
  deleteWorkLocation?: Maybe<DeleteWorkLocationPayload>
  /** Disconnect an aggregator/OMNY connection */
  disconnectConnection?: Maybe<DisconnectConnectionPayload>
  /** Employee connects an existing OMNY card by providing credentials */
  employeeConnectOmnyCard?: Maybe<ConnectOmnyCardPayload>
  /** Employee requests we ship them a new OMNY card */
  employeeRequestOmnyCard?: Maybe<RequestOmnyCardPayload>
  /** Login with a phone code */
  loginByPhoneCode?: Maybe<LoginByPhoneCodePayload>
  /** Logout the current user - clear session */
  logout?: Maybe<LogoutPayload>
  /** Notify an existing organization admin that KYC needs to be completed */
  notifyAdminKyc?: Maybe<NotifyAdminKycPayload>
  /** Record that the employer has completed payroll instructions */
  payrollInstructionsComplete?: Maybe<PayrollInstructionsCompletePayload>
  /**
   * Request an Alice Card
   * @deprecated Use requestAliceCard instead
   */
  provisionAliceCard?: Maybe<RequestAliceCardPayload>
  /** Reconnect OMNY connection by providing updated credentials */
  reconnectOmnyConnection?: Maybe<ReconnectOmnyConnectionPayload>
  /** Re-enroll an employee */
  reenrollEmployee?: Maybe<ReenrollEmployeePayload>
  /** Register an employee */
  registerEmployee?: Maybe<RegisterEmployeePayload>
  /** Report a missing Alice Card */
  reportMissingAliceCard?: Maybe<ReportMissingAliceCardPayload>
  /** Request an Alice Card */
  requestAliceCard?: Maybe<RequestAliceCardPayload>
  /** Set the banking account for an organization or employer */
  setBankingAccount?: Maybe<SetBankingAccountPayload>
  /** Set the payment card for an organization or paygroup using a Stripe token */
  setPaymentCard?: Maybe<SetPaymentCardPayload>
  /** Submit documents required to start onboarding, including order form, org tos, and e-signature consent */
  submitOnboardingDocuments?: Maybe<SubmitOnboardingDocumentsPayload>
  /** Update organization-level configuration related to the Alice Card program */
  updateAliceCardProgram?: Maybe<UpdateAliceCardProgramPayload>
  /** Update billing configuration for an organization */
  updateBillingConfig?: Maybe<UpdateBillingConfigPayload>
  /** Update an employee with supported attributes */
  updateEmployee?: Maybe<UpdateEmployeePayload>
  /** Bulk update employees */
  updateEmployees?: Maybe<UpdateEmployeesPayload>
  /** Update a form submission with new or modified answers */
  updateFormSubmission?: Maybe<UpdateFormSubmissionPayload>
  /** Handle success or failure of a Plaid Link session */
  updateLinkSession?: Maybe<UpdateLinkSessionPayload>
  /** Update OMNY card shipment to mark it received or missing */
  updateOmnyCardShipment?: Maybe<UpdateOmnyCardShipmentPayload>
  /** Update an organization */
  updateOrganization?: Maybe<UpdateOrganizationPayload>
  /** Update a user with supported attributes */
  updateUser?: Maybe<UpdateUserPayload>
  /** Update an organization's work location */
  updateWorkLocation?: Maybe<UpdateWorkLocationPayload>
  /** A simple notification mutation to send a help request message to the support team. */
  userHelpRequest?: Maybe<UserHelpRequestPayload>
  /** Sign up a new user */
  userSignup?: Maybe<UserSignupPayload>
  /** Confirm user's phone number by validating code sent via SMS */
  verifyPhone?: Maybe<VerifyPhonePayload>
}

export type MutationConfirmTransactionsArgs = {
  input: ConfirmTransactionsInput
}

export type MutationCreateAccountSessionArgs = {
  input: CreateAccountSessionInput
}

export type MutationCreateAdminKycLinkArgs = {
  input: CreateAdminKycLinkInput
}

export type MutationCreateAliceCardAchPaymentSourceArgs = {
  input: CreateAchPaymentSourceInput
}

export type MutationCreateAliceCardPaymentMethodArgs = {
  input: CreatePaymentMethodInput
}

export type MutationCreateAliceCardPaymentMethodConnectionArgs = {
  input: CreatePaymentMethodConnectionInput
}

export type MutationCreateBillingSetupIntentArgs = {
  input: CreateBillingSetupIntentInput
}

export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput
}

export type MutationCreateFinchConnectionArgs = {
  input: CreateFinchConnectionInput
}

export type MutationCreateFinicityConnectUrlArgs = {
  input: CreateFinicityConnectUrlInput
}

export type MutationCreateFinicityConnectionArgs = {
  input: CreateFinicityConnectionInput
}

export type MutationCreateFormSubmissionArgs = {
  input: CreateFormSubmissionInput
}

export type MutationCreateHostedLinkSessionArgs = {
  input: CreateHostedLinkSessionInput
}

export type MutationCreateIssuingSetupIntentArgs = {
  input: CreateIssuingSetupIntentInput
}

export type MutationCreateLinkSessionArgs = {
  input: CreateLinkSessionInput
}

export type MutationCreateOrgFromProvisionalArgs = {
  input: CreateOrgFromProvisionalInput
}

export type MutationCreatePhoneLoginCodeArgs = {
  input: CreatePhoneLoginCodeInput
}

export type MutationCreatePhoneVerificationCodeArgs = {
  input: CreatePhoneVerificationCodeInput
}

export type MutationCreateWorkLocationArgs = {
  input: CreateWorkLocationInput
}

export type MutationDeleteBillingSourceArgs = {
  input: DeleteBillingSourceInput
}

export type MutationDeleteWorkLocationArgs = {
  input: DeleteWorkLocationInput
}

export type MutationDisconnectConnectionArgs = {
  input: DisconnectConnectionInput
}

export type MutationEmployeeConnectOmnyCardArgs = {
  input: ConnectOmnyCardInput
}

export type MutationEmployeeRequestOmnyCardArgs = {
  input: RequestOmnyCardInput
}

export type MutationLoginByPhoneCodeArgs = {
  input: LoginByPhoneCodeInput
}

export type MutationLogoutArgs = {
  input: LogoutInput
}

export type MutationNotifyAdminKycArgs = {
  input: NotifyAdminKycInput
}

export type MutationPayrollInstructionsCompleteArgs = {
  input: PayrollInstructionsCompleteInput
}

export type MutationProvisionAliceCardArgs = {
  input: RequestAliceCardInput
}

export type MutationReconnectOmnyConnectionArgs = {
  input: ReconnectOmnyConnectionInput
}

export type MutationReenrollEmployeeArgs = {
  input: ReenrollEmployeeInput
}

export type MutationRegisterEmployeeArgs = {
  input: RegisterEmployeeInput
}

export type MutationReportMissingAliceCardArgs = {
  input: ReportMissingAliceCardInput
}

export type MutationRequestAliceCardArgs = {
  input: RequestAliceCardInput
}

export type MutationSetBankingAccountArgs = {
  input: SetBankingAccountInput
}

export type MutationSetPaymentCardArgs = {
  input: SetPaymentCardInput
}

export type MutationSubmitOnboardingDocumentsArgs = {
  input: SubmitOnboardingDocumentsInput
}

export type MutationUpdateAliceCardProgramArgs = {
  input: UpdateAliceCardProgramInput
}

export type MutationUpdateBillingConfigArgs = {
  input: UpdateBillingConfigInput
}

export type MutationUpdateEmployeeArgs = {
  input: UpdateEmployeeInput
}

export type MutationUpdateEmployeesArgs = {
  input: UpdateEmployeesInput
}

export type MutationUpdateFormSubmissionArgs = {
  input: UpdateFormSubmissionInput
}

export type MutationUpdateLinkSessionArgs = {
  input: UpdateLinkSessionInput
}

export type MutationUpdateOmnyCardShipmentArgs = {
  input: UpdateOmnyCardShipmentInput
}

export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateWorkLocationArgs = {
  input: UpdateWorkLocationInput
}

export type MutationUserHelpRequestArgs = {
  input: UserHelpRequestInput
}

export type MutationUserSignupArgs = {
  input: UserSignupInput
}

export type MutationVerifyPhoneArgs = {
  input: VerifyPhoneInput
}

/** The delivery channel for the notification */
export enum NotificationChannel {
  Email = 'email',
  PushNotification = 'push_notification',
  Sms = 'sms',
}

/** A notification preference */
export type NotificationPreference = {
  __typename?: 'NotificationPreference'
  channel: NotificationChannel
  enabled: Scalars['Boolean']['output']
  id: Scalars['Int']['output']
  notificationType: NotificationType
}

/** A specific notification preference */
export type NotificationPreferenceInput = {
  /** The channel to update the notification preference for */
  channel: NotificationChannel
  /** Whether the notification should be enabled */
  enabled: Scalars['Boolean']['input']
  notificationType: NotificationType
}

/** The type of notification */
export enum NotificationType {
  InvoiceEvents = 'invoice_events',
  MonthlyFinance = 'monthly_finance',
  PbaWithdrawal = 'pba_withdrawal',
}

/** Autogenerated input type of NotifyAdminKYC */
export type NotifyAdminKycInput = {
  /** The ID of the org admin employee to notify */
  adminId?: InputMaybe<Scalars['Int']['input']>
  /** The ID of the org admin user to notify */
  adminUserId?: InputMaybe<Scalars['Int']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of NotifyAdminKYC. */
export type NotifyAdminKycPayload = {
  __typename?: 'NotifyAdminKYCPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  notificationSent?: Maybe<Scalars['Boolean']['output']>
  organization?: Maybe<Organization>
}

/** Organization-specific state to manage onboarding progress */
export type OnboardingState = {
  __typename?: 'OnboardingState'
  /** Org has submitted Alice Card KYC to Stripe */
  isAliceCardProgramActive: Scalars['Boolean']['output']
  /** Org is receiving or ready to receive services */
  isAlreadyLaunched: Scalars['Boolean']['output']
  /** Org has a billing source */
  isBillingConnected: Scalars['Boolean']['output']
  /** Org has completed all necessary onboarding documents */
  isDoneWithOnboardingDocuments: Scalars['Boolean']['output']
  /** Org admin confirmed payroll payroll connection */
  isDoneWithPayrollInstructions: Scalars['Boolean']['output']
  /** Launch date is in the future */
  isLaunchingInFuture: Scalars['Boolean']['output']
  /** Launch date is today */
  isLaunchingToday: Scalars['Boolean']['output']
  /** Org has uploaded a logo */
  isLogoPresent: Scalars['Boolean']['output']
  /** Admin has completed org survey */
  isOrgOnboardingSurveyComplete: Scalars['Boolean']['output']
  /** Alice-confirmed payroll connection */
  isPayrollConnected: Scalars['Boolean']['output']
  /** Org has finished payroll onboarding */
  isPayrollConnectionComplete: Scalars['Boolean']['output']
  /** Payroll, Billing, Alice Card, and launch date (in future) are complete */
  isReadyToLaunch: Scalars['Boolean']['output']
}

export type Organization = {
  __typename?: 'Organization'
  aliceCardFunding: AliceCardFunding
  /** Data about the organization's Alice Card program */
  aliceCardProgram: AliceCardProgram
  billing?: Maybe<Billing>
  /** The date that the organization will notify employees to enroll in Commuting */
  commutingLaunchDate?: Maybe<Scalars['FormattedDate']['output']>
  employees: EmployeeConnection
  /** An employer within the organization, specified by id */
  employer?: Maybe<Employer>
  employers: Array<Maybe<Employer>>
  id: Scalars['Int']['output']
  logo?: Maybe<Image>
  name: Scalars['String']['output']
  onboardingState: OnboardingState
  payPeriod?: Maybe<PayPeriod>
  payPeriods: PayPeriodConnection
  /** Data about a payroll connection for the organization */
  payrollConnection: PayrollConnection
  status: OrganizationStatus
  /** A location belonging to the organization, specified by id */
  workLocation?: Maybe<WorkLocation>
  workLocations: Array<Maybe<WorkLocation>>
}

export type OrganizationCommutingLaunchDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export type OrganizationEmployeesArgs = {
  accountStatuses?: InputMaybe<Array<EmployeeAccountStatus>>
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  employerId?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
  role?: InputMaybe<EmployeeRole>
  searchTerms?: InputMaybe<Array<Scalars['String']['input']>>
}

export type OrganizationEmployerArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
  inviteCode?: InputMaybe<Scalars['String']['input']>
}

export type OrganizationPayPeriodArgs = {
  id: Scalars['Int']['input']
}

export type OrganizationPayPeriodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  employerId?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type OrganizationWorkLocationArgs = {
  id: Scalars['Int']['input']
}

/** The status of the organization */
export enum OrganizationStatus {
  Active = 'active',
  Inactive = 'inactive',
  PendingCancellation = 'pending_cancellation',
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo'
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output']
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output']
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>
}

/** Employee accounting ledger for the current year's mass transit benefit */
export type ParkingLedger = {
  __typename?: 'ParkingLedger'
  createdAt: Scalars['ISO8601DateTime']['output']
  /** Balance for current month's spend. Returns the month of whatever day it is today */
  current?: Maybe<LegerBalance>
  id: Scalars['Int']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
  /** YTD summary of spend for parking benefits */
  yearToDate?: Maybe<LegerBalance>
}

/** Pay period for an employer */
export type PayPeriod = {
  __typename?: 'PayPeriod'
  /** The number of employees in question for this pay period. */
  employeeCount: Scalars['Int']['output']
  endDate: Scalars['FormattedDate']['output']
  id: Scalars['Int']['output']
  /** Invoice for the pay period */
  invoice?: Maybe<Invoice>
  /** Employee pay period records */
  payPeriodRecords: Array<PayPeriodRecord>
  payday: Scalars['FormattedDate']['output']
  /** Paygroup info for the pay period */
  paygroupInfo: EmployerInfo
  startDate: Scalars['FormattedDate']['output']
  status: PayPeriodStatus
  /** Total employee deductions for the pay period */
  totalEmployeeDeduction: Scalars['Int']['output']
  /** Total amount reimbursed to employees for the pay period */
  totalEmployeeReimbursement: Scalars['Int']['output']
  /** Estimated employee tax savings for the pay period */
  totalEmployeeSavings: Scalars['Int']['output']
  /**
   * Estimated subsidy amount for the pay period
   * @deprecated This is an expensive field to calculate, and is better exposed on invoice
   */
  totalSubsidy: Scalars['Int']['output']
}

/** Pay period for an employer */
export type PayPeriodEndDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** Pay period for an employer */
export type PayPeriodPaydayArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** Pay period for an employer */
export type PayPeriodStartDateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** The connection type for PayPeriod. */
export type PayPeriodConnection = {
  __typename?: 'PayPeriodConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayPeriodEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PayPeriod>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type PayPeriodEdge = {
  __typename?: 'PayPeriodEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<PayPeriod>
}

/** Record of employee's pay period for a particular benefit */
export type PayPeriodRecord = {
  __typename?: 'PayPeriodRecord'
  benefitType?: Maybe<BenefitType>
  /** Amount of deduction in cents */
  deduction?: Maybe<Scalars['Int']['output']>
  employee: Employee
  id: Scalars['Int']['output']
  isPending: Scalars['Boolean']['output']
  /** Pay period associated with the record */
  payPeriod: PayPeriod
  /** Amount of reimbursement in cents */
  reimbursement?: Maybe<Scalars['Int']['output']>
}

/** The connection type for PayPeriodRecord. */
export type PayPeriodRecordConnection = {
  __typename?: 'PayPeriodRecordConnection'
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PayPeriodRecordEdge>>>
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PayPeriodRecord>>>
  /** Information to aid in pagination. */
  pageInfo: PageInfo
}

/** An edge in a connection. */
export type PayPeriodRecordEdge = {
  __typename?: 'PayPeriodRecordEdge'
  /** A cursor for use in pagination. */
  cursor: Scalars['String']['output']
  /** The item at the end of the edge. */
  node?: Maybe<PayPeriodRecord>
}

export enum PayPeriodStatus {
  Confirmed = 'confirmed',
  Invoiced = 'invoiced',
  NeedsReconciliation = 'needs_reconciliation',
  Paid = 'paid',
  PaidMismatch = 'paid_mismatch',
  PayrollRun = 'payroll_run',
  Pending = 'pending',
}

/** Configuration needed to set up payroll schedule for an organization */
export type PaySchedule = {
  __typename?: 'PaySchedule'
  daysBeforePaydaySubmitted: Scalars['Int']['output']
  lastDayOfMonth?: Maybe<Scalars['Boolean']['output']>
  lastDayOfPayPeriod: Scalars['FormattedDate']['output']
  nextPayday: Scalars['FormattedDate']['output']
  payrollFrequency: PayrollFrequency
  twiceMonthlyPayday1?: Maybe<Scalars['String']['output']>
  twiceMonthlyPayday2?: Maybe<Scalars['String']['output']>
  twiceMonthlyType?: Maybe<TwiceMonthlyType>
}

/** Configuration needed to set up payroll schedule for an organization */
export type PayScheduleLastDayOfPayPeriodArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** Configuration needed to set up payroll schedule for an organization */
export type PayScheduleNextPaydayArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export type PaygroupConfig = {
  __typename?: 'PaygroupConfig'
  finchConnection?: Maybe<FinchConnection>
}

/** Card attributes reported by billing system, e.g. Stripe. Full card number is not known to the server. */
export type PaymentCard = {
  __typename?: 'PaymentCard'
  /** ID of the card in the billing system (e.g. Stripe) */
  billingSystemId?: Maybe<Scalars['String']['output']>
  brand: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  error?: Maybe<Error>
  expMonth: Scalars['Int']['output']
  expYear: Scalars['Int']['output']
  /** ID of the local Billing::PaymentCard instance */
  id: Scalars['Int']['output']
  last4: Scalars['String']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Card attributes as returned by billing system, e.g. Stripe */
export type PaymentCardInput = {
  brand: Scalars['String']['input']
  exp_month: Scalars['Int']['input']
  exp_year: Scalars['Int']['input']
  /** ID of the card in the billing system */
  id: Scalars['String']['input']
  last4: Scalars['String']['input']
}

/** A payment method for funding an Alice Card program */
export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  descriptor: Scalars['String']['output']
  id?: Maybe<Scalars['Int']['output']>
  last4?: Maybe<Scalars['String']['output']>
  status: PaymentMethodStatus
  /** The ID of the payment method in Stripe, if connected */
  stripeSystemId?: Maybe<Scalars['String']['output']>
}

/** A connection between an employer and a payment method */
export type PaymentMethodConnection = {
  __typename?: 'PaymentMethodConnection'
  id?: Maybe<Scalars['Int']['output']>
  isActive: Scalars['Boolean']['output']
  isCurrent: Scalars['Boolean']['output']
  paymentMethod: PaymentMethod
}

export enum PaymentMethodStatus {
  /** Active */
  Active = 'active',
  /** Error */
  Error = 'error',
  /** Inactive */
  Inactive = 'inactive',
  /** Info stored locally before Stripe setup is complete */
  PendingSetup = 'pending_setup',
  /** Pending verification */
  PendingVerification = 'pending_verification',
}

/** Attributes used to save payment card info held by Stripe */
export type PaymentTokenInput = {
  card: PaymentCardInput
  id: Scalars['String']['input']
}

/** Data about a payroll connection for an organization */
export type PayrollConnection = {
  __typename?: 'PayrollConnection'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  isFinchEnabled: Scalars['Boolean']['output']
  payPeriods: PayPeriodConnection
  /** The date and time that the payroll connection was confirmed as complete by Alice */
  payrollConnectedAt?: Maybe<Scalars['ISO8601Date']['output']>
  /** The date and time when the organization admin completed payroll instructions, if known */
  payrollInstructionsCompletedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  payrollPlatform?: Maybe<PayrollPlatform>
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Data about a payroll connection for an organization */
export type PayrollConnectionPayPeriodsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  employerId?: InputMaybe<Scalars['Int']['input']>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export enum PayrollFrequency {
  Fortnightly = 'fortnightly',
  Monthly = 'monthly',
  TwicePerMonth = 'twice_per_month',
  TwicePerMonthFixedDay = 'twice_per_month_fixed_day',
  Weekly = 'weekly',
}

/** Autogenerated input type of PayrollInstructionsComplete */
export type PayrollInstructionsCompleteInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of PayrollInstructionsComplete. */
export type PayrollInstructionsCompletePayload = {
  __typename?: 'PayrollInstructionsCompletePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** A payroll provider used by an organization */
export type PayrollPlatform = {
  __typename?: 'PayrollPlatform'
  code: Scalars['String']['output']
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  name: Scalars['String']['output']
  permitsAdminReports: Scalars['Boolean']['output']
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Data related to a Plaid Link session */
export type PlaidLinkSession = {
  __typename?: 'PlaidLinkSession'
  bankingConnection?: Maybe<BankingConnection>
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  linkToken: Scalars['String']['output']
  status: PlaidLinkSessionStatusEnum
  updatedAt: Scalars['ISO8601DateTime']['output']
}

/** Status of the link session */
export enum PlaidLinkSessionStatusEnum {
  Completed = 'completed',
  Created = 'created',
  Failed = 'failed',
}

/** The plan year for a particular enrollment */
export type PlanYear = {
  __typename?: 'PlanYear'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  period: ClosedTimePeriod
  updatedAt: Scalars['ISO8601DateTime']['output']
}

export enum PretaxCategory {
  Dental = 'dental',
  DependentCare = 'dependent_care',
  Healthcare = 'healthcare',
  HealthcarePharmacy = 'healthcare_pharmacy',
  MassTransit = 'mass_transit',
  NotEligible = 'not_eligible',
  Parking = 'parking',
  Vision = 'vision',
}

export type PricingAdjustment = {
  __typename?: 'PricingAdjustment'
  /** Adjustment amount */
  amount: Scalars['Int']['output']
  id: Scalars['Int']['output']
  /** Reason for adjustment */
  reason?: Maybe<Scalars['String']['output']>
}

export enum ProvisionAliceCardScopeEnum {
  Instant = 'instant',
  InstantAndPhysical = 'instant_and_physical',
  Physical = 'physical',
}

export type ProvisionalOrganization = {
  __typename?: 'ProvisionalOrganization'
  address?: Maybe<AddressDisplay>
  id: Scalars['Int']['output']
  isReadyForConversion: Scalars['Boolean']['output']
  isTosAccepted: Scalars['Boolean']['output']
  legalName: Scalars['String']['output']
  organization?: Maybe<Organization>
  paySchedule?: Maybe<PaySchedule>
  payrollPlatform?: Maybe<PayrollPlatform>
}

export type Query = {
  __typename?: 'Query'
  /** Banking connection belonging to the requesting user */
  bankingConnection?: Maybe<BankingConnection>
  /** Banking transaction belonging to the requesting user */
  bankingTransaction: BankingTransaction
  /** Paginated banking transactions belonging to the requesting user's canonical employee */
  bankingTransactions: BankingTransactionConnection
  /** User making the API request, or null if request is anonymous */
  currentUser?: Maybe<User>
  /** The employee specified by id, or the requesting user's canonical employee */
  employee?: Maybe<Employee>
  /** Public enrollment-related employer information */
  enrollmentEmployer?: Maybe<EnrollmentEmployer>
  formTemplate?: Maybe<FormsTemplate>
  /** The organization specified by id, or the requesting user's canonical org */
  organization?: Maybe<Organization>
  searchBankingInstitutions?: Maybe<Array<UnifiedInstitution>>
}

export type QueryBankingConnectionArgs = {
  id: Scalars['Int']['input']
}

export type QueryBankingTransactionArgs = {
  id: Scalars['ID']['input']
}

export type QueryBankingTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>
  before?: InputMaybe<Scalars['String']['input']>
  confirmationStatus?: InputMaybe<ConfirmationStatusValue>
  first?: InputMaybe<Scalars['Int']['input']>
  last?: InputMaybe<Scalars['Int']['input']>
}

export type QueryEmployeeArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QueryEnrollmentEmployerArgs = {
  email?: InputMaybe<Scalars['String']['input']>
  inviteCode?: InputMaybe<Scalars['String']['input']>
}

export type QueryFormTemplateArgs = {
  templateType: Scalars['String']['input']
}

export type QueryOrganizationArgs = {
  id?: InputMaybe<Scalars['Int']['input']>
}

export type QuerySearchBankingInstitutionsArgs = {
  name?: InputMaybe<Scalars['String']['input']>
  preferredPlatform?: InputMaybe<BankingConnectionPlatform>
}

export enum QuestionType {
  Boolean = 'boolean',
  Multiple = 'multiple',
  Select = 'select',
  Text = 'text',
}

/** Autogenerated input type of ReconnectOMNYConnection */
export type ReconnectOmnyConnectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  connectionId: Scalars['Int']['input']
  password: Scalars['String']['input']
  username: Scalars['String']['input']
}

/** Autogenerated return type of ReconnectOMNYConnection. */
export type ReconnectOmnyConnectionPayload = {
  __typename?: 'ReconnectOMNYConnectionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  connection?: Maybe<BankingConnection>
  inputErrors: Array<InputError>
}

/** Autogenerated input type of ReenrollEmployee */
export type ReenrollEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeId: Scalars['Int']['input']
}

/** Autogenerated return type of ReenrollEmployee. */
export type ReenrollEmployeePayload = {
  __typename?: 'ReenrollEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of RegisterEmployee */
export type RegisterEmployeeInput = {
  /** Accept Alice Card TOS and enroll (might not provision automatically) */
  acceptAliceCardTos?: InputMaybe<Scalars['Boolean']['input']>
  /** Accept terms and esign */
  acceptTermsAndEsign: Scalars['Boolean']['input']
  /** Birthdate - required for all Alice Card provisioning */
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The user's e-mail */
  email: Scalars['String']['input']
  /** Employment start date */
  employmentStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  /** First name */
  firstName: Scalars['String']['input']
  /** The invite code for the employer */
  inviteCode: Scalars['String']['input']
  /** preferred language code as enum, e.g. 'en_us' or 'es' */
  language?: InputMaybe<LanguagePreference>
  /** Last name */
  lastName: Scalars['String']['input']
  /** Mailing address - verification will be attempted if supplied */
  mailingAddress?: InputMaybe<AddressInput>
  /** Password */
  password?: InputMaybe<Scalars['String']['input']>
  /** Password confirmation */
  passwordConfirmation?: InputMaybe<Scalars['String']['input']>
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']['input']>
  /** ID of referring employee */
  referredBy?: InputMaybe<Scalars['Int']['input']>
  /** The user's mobile phone number */
  tel: Scalars['String']['input']
  utmParams?: InputMaybe<UtmParams>
}

/** Autogenerated return type of RegisterEmployee. */
export type RegisterEmployeePayload = {
  __typename?: 'RegisterEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee?: Maybe<Employee>
  inputErrors: Array<InputError>
}

/** A record of this transaction being partly or full reimbursed */
export type Reimbursement = {
  __typename?: 'Reimbursement'
  amount: Scalars['Int']['output']
  date: Scalars['ISO8601DateTime']['output']
}

export type ReimbursementClaim = {
  __typename?: 'ReimbursementClaim'
  approvalStatus: ReimbursementClaimApprovalStatus
  claimDocuments: Array<ReimbursementClaimDocument>
  id: Scalars['Int']['output']
  reviewedAt?: Maybe<Scalars['FormattedDate']['output']>
  reviewerNote?: Maybe<Scalars['String']['output']>
}

export type ReimbursementClaimReviewedAtArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

export enum ReimbursementClaimApprovalStatus {
  Approved = 'approved',
  ApprovedRequiresManualReimbursement = 'approved_requires_manual_reimbursement',
  Denied = 'denied',
  NotRequired = 'not_required',
  Pending = 'pending',
}

/** Claim document, with image */
export type ReimbursementClaimDocument = {
  __typename?: 'ReimbursementClaimDocument'
  createdAt: Scalars['ISO8601DateTime']['output']
  id: Scalars['Int']['output']
  image?: Maybe<Image>
}

/** Information about the state of reimbursement for this transaction */
export type ReimbursementStatus = {
  __typename?: 'ReimbursementStatus'
  isFullyReimbursed: Scalars['Boolean']['output']
  reimbursements: Array<Reimbursement>
}

export type ReimbursementSummary = {
  __typename?: 'ReimbursementSummary'
  confirmedSpend: Scalars['Int']['output']
  savedSoFarEstimate: Scalars['Int']['output']
}

/** Autogenerated input type of ReportMissingAliceCard */
export type ReportMissingAliceCardInput = {
  aliceCardId: Scalars['Int']['input']
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of ReportMissingAliceCard. */
export type ReportMissingAliceCardPayload = {
  __typename?: 'ReportMissingAliceCardPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of RequestAliceCard */
export type RequestAliceCardInput = {
  /** The birthdate of the employee, if not already known */
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>
  cardTypesRequested?: InputMaybe<CardRequestTypeEnum>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The mailing address for the card, if different from employee's current address */
  mailingAddress?: InputMaybe<AddressInput>
  /** Acceptance of the Alice Card TOS, if not previously accepted */
  tosAccepted?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of RequestAliceCard. */
export type RequestAliceCardPayload = {
  __typename?: 'RequestAliceCardPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee?: Maybe<Employee>
  inputErrors: Array<InputError>
}

/** Autogenerated input type of RequestOMNYCard */
export type RequestOmnyCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of RequestOMNYCard. */
export type RequestOmnyCardPayload = {
  __typename?: 'RequestOMNYCardPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  connection?: Maybe<BankingConnection>
  inputErrors: Array<InputError>
}

/** A role assigned to a user for a resource */
export type Role = {
  __typename?: 'Role'
  id: Scalars['Int']['output']
  label?: Maybe<Scalars['String']['output']>
  resourceDisplayInfo: RoleResource
  roleName: RoleName
}

/** The name of a role */
export enum RoleName {
  BusinessAdmin = 'business_admin',
}

/** Summary of the resource a role is assigned to */
export type RoleResource = {
  __typename?: 'RoleResource'
  /** The display name of the resource, if available */
  displayName?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  /** The type of the resource */
  resourceType: RoleResourceType
}

/** The type of resource a role is assigned to */
export enum RoleResourceType {
  Organization = 'Organization',
}

/** The type of role (class name) */
export enum RoleType {
  OrganizationRole = 'OrganizationRole',
}

/** Autogenerated input type of SetBankingAccount */
export type SetBankingAccountInput = {
  accountNumber?: InputMaybe<Scalars['String']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ownerId: Scalars['Int']['input']
  ownerType: BillableType
  paymentMethodId: Scalars['String']['input']
  routingNumber?: InputMaybe<Scalars['String']['input']>
  verificationUrl?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of SetBankingAccount. */
export type SetBankingAccountPayload = {
  __typename?: 'SetBankingAccountPayload'
  bankingAccount?: Maybe<BankingAccount>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
}

/** Autogenerated input type of SetPaymentCard */
export type SetPaymentCardInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  ownerId: Scalars['Int']['input']
  ownerType: BillableType
  token: PaymentTokenInput
}

/** Autogenerated return type of SetPaymentCard. */
export type SetPaymentCardPayload = {
  __typename?: 'SetPaymentCardPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  paymentCard?: Maybe<PaymentCard>
}

export type SpendEligibility = {
  __typename?: 'SpendEligibility'
  autoConfirmedAt?: Maybe<Scalars['ISO8601DateTime']['output']>
  confirmationStatus: ConfirmationStatus
  confirmedPartialAmount?: Maybe<Scalars['Int']['output']>
  isDisconfirmable?: Maybe<Scalars['Boolean']['output']>
  pretaxCategory: PretaxCategory
}

/** Spending connection information for an employee */
export type SpendingConnectionInfo = {
  __typename?: 'SpendingConnectionInfo'
  aliceCard?: Maybe<AliceCard>
  /** The employee's primary Alice Card account */
  aliceCardAccount?: Maybe<AliceCardAccount>
  /** The employee's Alice Card accounts - generally should prefer aliceCardAccount */
  aliceCardAccounts?: Maybe<Array<AliceCardAccount>>
  balanceInfo: BalanceInfo
  bankingConnections?: Maybe<Array<BankingConnection>>
  hasAcceptedAliceCardTos: Scalars['Boolean']['output']
  hasRequestedAliceCard: Scalars['Boolean']['output']
  plaid: EmployeePlaid
}

/** Spending connection information for an employee */
export type SpendingConnectionInfoAliceCardArgs = {
  id: Scalars['Int']['input']
}

/** Spending information for an employee */
export type SpendingInfo = {
  __typename?: 'SpendingInfo'
  /** Whether the employee has any auto-confirmed transactions in the last 30 days */
  hasRecentAutoConfirmedTxns: Scalars['Boolean']['output']
  /** The total estimated pre-tax savings for the employee */
  totalEstimatedPretaxSavings?: Maybe<Scalars['Int']['output']>
}

/** An organization's Company Account that records its Alice Card data */
export type StripeCompanyAccount = {
  __typename?: 'StripeCompanyAccount'
  accountStatus: StripeCompanyAccountStatusEnum
  id: Scalars['Int']['output']
  /**
   * The connected account ID provided by Stripe
   * @deprecated Use `stripe_financial_account_id` instead
   */
  stripeAccountId?: Maybe<Scalars['String']['output']>
  /** The connected account ID provided by Stripe, acct_xxx */
  stripeConnectedAccountId?: Maybe<Scalars['String']['output']>
  /** The financial account ID provided by Stripe, fa_xxx */
  stripeFinancialAccountId?: Maybe<Scalars['String']['output']>
  /** used by Stripe.js widget to create a SetupIntent */
  stripeSetupIntentSecret?: Maybe<Scalars['String']['output']>
}

export enum StripeCompanyAccountStatusEnum {
  /** Active */
  Active = 'active',
  /** Deactivated */
  Deactivated = 'deactivated',
  /** Not submitted */
  NotSubmitted = 'not_submitted',
  /** Submitted */
  Submitted = 'submitted',
}

/** A Stripe Card's digital wallet configuration */
export type StripeWallets = {
  __typename?: 'StripeWallets'
  applePay?: Maybe<WalletInfo>
  googlePay?: Maybe<WalletInfo>
  primaryAccountIdentifier?: Maybe<Scalars['String']['output']>
}

/** An entity that owns or is the subject of a form */
export enum SubmissionRelation {
  Employee = 'employee',
  Organization = 'organization',
  User = 'user',
}

/** Autogenerated input type of SubmitOnboardingDocuments */
export type SubmitOnboardingDocumentsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  contractLength?: InputMaybe<Scalars['Int']['input']>
  esignConsentAccepted: Scalars['Boolean']['input']
  isReferenceCustomer?: InputMaybe<Scalars['Boolean']['input']>
  onboardingTimelineAccepted: Scalars['Boolean']['input']
  orgId: Scalars['Int']['input']
  orgTosAccepted: Scalars['Boolean']['input']
  ownerSignature: Scalars['String']['input']
  ownerTitle: Scalars['String']['input']
}

/** Autogenerated return type of SubmitOnboardingDocuments. */
export type SubmitOnboardingDocumentsPayload = {
  __typename?: 'SubmitOnboardingDocumentsPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
  success: Scalars['Boolean']['output']
}

/** The type of tel-based login error */
export enum TelLoginErrorCode {
  AccountLocked = 'account_locked',
  ExpiredCode = 'expired_code',
  InvalidCode = 'invalid_code',
  InvalidPassword = 'invalid_password',
  InvalidTel = 'invalid_tel',
}

/** A telephone number with metadata */
export type Telephone = {
  __typename?: 'Telephone'
  countryCode: Scalars['String']['output']
  /** Whether a confirmation code has been sent and is still valid */
  isConfirmationCodeSent?: Maybe<Scalars['Boolean']['output']>
  /** Confirmed as user's number through SMS */
  isSmsConfirmed?: Maybe<Scalars['Boolean']['output']>
  /** Number verified by phone lookup service */
  isVerified?: Maybe<Scalars['Boolean']['output']>
  /** Subscriber number, without country code */
  nationalNumber: Scalars['String']['output']
}

/** A representation of a time duration with a fixed starting date and time - may be infinite */
export type TimePeriod = {
  __typename?: 'TimePeriod'
  endDate?: Maybe<Scalars['ISO8601DateTime']['output']>
  startDate: Scalars['ISO8601DateTime']['output']
}

export enum TwiceMonthlyType {
  FifteenthAndLast = 'fifteenth_and_last',
  Other = 'other',
}

/** A unified institution is a single institution that may be represented in multiple platforms */
export type UnifiedInstitution = {
  __typename?: 'UnifiedInstitution'
  id: Scalars['Int']['output']
  logoUrl?: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  platformId: Scalars['String']['output']
  preferredPlatform: BankingConnectionPlatform
}

/** Autogenerated input type of UpdateAliceCardProgram */
export type UpdateAliceCardProgramInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the organization should be funded at the org level or the paygroup level */
  hasUnifiedBanking?: InputMaybe<Scalars['Boolean']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of UpdateAliceCardProgram. */
export type UpdateAliceCardProgramPayload = {
  __typename?: 'UpdateAliceCardProgramPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of UpdateBillingConfig */
export type UpdateBillingConfigInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** Whether the organization should be billed at the org level or the paygroup level */
  hasUnifiedBilling?: InputMaybe<Scalars['Boolean']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of UpdateBillingConfig. */
export type UpdateBillingConfigPayload = {
  __typename?: 'UpdateBillingConfigPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of UpdateEmployee */
export type UpdateEmployeeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeId: Scalars['Int']['input']
  /** Employment start date */
  employmentStartDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  /** Mailing address, with optional verification check */
  mailingAddress?: InputMaybe<AddressInput>
}

/** Autogenerated return type of UpdateEmployee. */
export type UpdateEmployeePayload = {
  __typename?: 'UpdateEmployeePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee?: Maybe<Employee>
  inputErrors: Array<InputError>
}

/** Autogenerated input type of UpdateEmployees */
export type UpdateEmployeesInput = {
  accountStatus?: InputMaybe<EmployeeAccountStatusOverride>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeIds: Array<Scalars['Int']['input']>
  role?: InputMaybe<EmployeeRole>
}

/** Autogenerated return type of UpdateEmployees. */
export type UpdateEmployeesPayload = {
  __typename?: 'UpdateEmployeesPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of UpdateFormSubmission */
export type UpdateFormSubmissionInput = {
  /** Array of [questionId, id, text] tuples */
  answers?: InputMaybe<Array<AnswerInputObject>>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** ID of the submission to update */
  id: Scalars['Int']['input']
  /** Updated status of the form submission */
  status?: InputMaybe<FormSubmissionStatus>
}

/** Autogenerated return type of UpdateFormSubmission. */
export type UpdateFormSubmissionPayload = {
  __typename?: 'UpdateFormSubmissionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  submissionId: Scalars['Int']['output']
}

/** Autogenerated input type of UpdateLinkSession */
export type UpdateLinkSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  employeeId: Scalars['Int']['input']
  failed?: InputMaybe<Scalars['Boolean']['input']>
  linkToken: Scalars['String']['input']
  publicToken?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateLinkSession. */
export type UpdateLinkSessionPayload = {
  __typename?: 'UpdateLinkSessionPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  employee: Employee
  inputErrors: Array<InputError>
}

/** Autogenerated input type of UpdateOMNYCardShipment */
export type UpdateOmnyCardShipmentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  shipmentId: Scalars['Int']['input']
  status: CardShipmentStatus
}

/** Autogenerated return type of UpdateOMNYCardShipment. */
export type UpdateOmnyCardShipmentPayload = {
  __typename?: 'UpdateOMNYCardShipmentPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  connection?: Maybe<BankingConnection>
  inputErrors: Array<InputError>
}

/** Autogenerated input type of UpdateOrganization */
export type UpdateOrganizationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The date that the organization will launch the Commuting product */
  commutingLaunchDate?: InputMaybe<Scalars['ISO8601Date']['input']>
  orgId: Scalars['Int']['input']
}

/** Autogenerated return type of UpdateOrganization. */
export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  organization?: Maybe<Organization>
}

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  birthdate?: InputMaybe<Scalars['ISO8601Date']['input']>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The user's preferred language */
  languagePreference?: InputMaybe<LanguagePreference>
  /** User notification preference assignments */
  notificationPreferences?: InputMaybe<Array<NotificationPreferenceInput>>
  userId: Scalars['Int']['input']
}

/** Autogenerated return type of UpdateUser. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  user?: Maybe<User>
}

/** Autogenerated input type of UpdateWorkLocation */
export type UpdateWorkLocationInput = {
  /** The address of the workLocation */
  address?: InputMaybe<AddressInput>
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  /** The latitude of the workLocation */
  latitude?: InputMaybe<Scalars['Float']['input']>
  /** The longitude of the workLocation */
  longitude?: InputMaybe<Scalars['Float']['input']>
  /** The name of the workLocation */
  name?: InputMaybe<Scalars['String']['input']>
  /** The user-provided nickname of the workLocation */
  nickname?: InputMaybe<Scalars['String']['input']>
}

/** Autogenerated return type of UpdateWorkLocation. */
export type UpdateWorkLocationPayload = {
  __typename?: 'UpdateWorkLocationPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  inputErrors: Array<InputError>
}

/** An individual person who uses Alice */
export type User = {
  __typename?: 'User'
  access: FeatureAccess
  /** User's birthdate */
  birthdate?: Maybe<Scalars['FormattedDate']['output']>
  email?: Maybe<Scalars['String']['output']>
  /** All employee instances associated with the user - prefer root-level canonical 'employee' query */
  employees?: Maybe<Array<Employee>>
  firstName?: Maybe<Scalars['String']['output']>
  /** All form submissions associated with the user */
  formSubmissions?: Maybe<Array<FormsSubmission>>
  /** Has the user set their own password */
  hasDummyPassword: Scalars['Boolean']['output']
  helpScoutSecureBeaconToken?: Maybe<Scalars['String']['output']>
  id: Scalars['Int']['output']
  identityVerification?: Maybe<Verification>
  /** Has the user has confirmed their email address */
  isEmailConfirmed: Scalars['Boolean']['output']
  /** The user's preferred language */
  languagePreference: LanguagePreference
  lastName?: Maybe<Scalars['String']['output']>
  notificationPreferences: Array<NotificationPreference>
  preferredName?: Maybe<Scalars['String']['output']>
  /** Provisional organizations that the user has created */
  provisionalOrganizations: Array<ProvisionalOrganization>
  /** All roles associated with the user session */
  roles: Array<Role>
  tel?: Maybe<Telephone>
}

/** An individual person who uses Alice */
export type UserBirthdateArgs = {
  format?: InputMaybe<Scalars['String']['input']>
}

/** An individual person who uses Alice */
export type UserRolesArgs = {
  roleType: RoleType
}

/** Autogenerated input type of UserHelpRequest */
export type UserHelpRequestInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  fromUrl: Scalars['String']['input']
  message: Scalars['String']['input']
  responseRequested?: InputMaybe<Scalars['Boolean']['input']>
}

/** Autogenerated return type of UserHelpRequest. */
export type UserHelpRequestPayload = {
  __typename?: 'UserHelpRequestPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** Autogenerated input type of UserSignup */
export type UserSignupInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** The user's e-mail */
  email: Scalars['String']['input']
  /** First name */
  firstName: Scalars['String']['input']
  /** Last name */
  lastName: Scalars['String']['input']
  /** Password */
  password: Scalars['String']['input']
  /** Password confirmation */
  passwordConfirmation: Scalars['String']['input']
  /** Preferred name */
  preferredName?: InputMaybe<Scalars['String']['input']>
  /** The user's mobile phone number */
  tel: Scalars['String']['input']
  utmParams?: InputMaybe<UtmParams>
}

/** Autogenerated return type of UserSignup. */
export type UserSignupPayload = {
  __typename?: 'UserSignupPayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  currentUser?: Maybe<User>
  inputErrors: Array<InputError>
}

/** UTM Params, snake_case */
export type UtmParams = {
  utm_campaign?: InputMaybe<Scalars['String']['input']>
  utm_content?: InputMaybe<Scalars['String']['input']>
  utm_medium?: InputMaybe<Scalars['String']['input']>
  utm_source?: InputMaybe<Scalars['String']['input']>
  utm_term?: InputMaybe<Scalars['String']['input']>
}

/** An identity verification */
export type Verification = {
  __typename?: 'Verification'
  id: Scalars['Int']['output']
  /** Have the documents been verified? */
  isVerified: Scalars['Boolean']['output']
}

/** Autogenerated input type of VerifyPhone */
export type VerifyPhoneInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>
  /** 4 digit code sent to the user to validate its phone number */
  confirmationCode: Scalars['String']['input']
}

/** Autogenerated return type of VerifyPhone. */
export type VerifyPhonePayload = {
  __typename?: 'VerifyPhonePayload'
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>
  inputErrors: Array<InputError>
  success: Scalars['Boolean']['output']
}

/** A Stripe Card's digital wallet eligibility */
export type WalletInfo = {
  __typename?: 'WalletInfo'
  eligible: Scalars['Boolean']['output']
  ineligibleReason?: Maybe<Scalars['String']['output']>
}

export type WorkLocation = {
  __typename?: 'WorkLocation'
  /** The address of the work location - only null if location setup is incomplete */
  address?: Maybe<AddressDisplay>
  /** @deprecated Use the employerInfo field instead */
  employer?: Maybe<Employer>
  employerInfo?: Maybe<Employer>
  enrollmentMaterials?: Maybe<EnrollmentMaterials>
  id: Scalars['Int']['output']
  latitude?: Maybe<Scalars['Float']['output']>
  logo?: Maybe<Image>
  longitude?: Maybe<Scalars['Float']['output']>
  name: Scalars['String']['output']
  nickname?: Maybe<Scalars['String']['output']>
}
