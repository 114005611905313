import { Container, FullDialog, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { useEmployeeEnrollmentInfoQuery } from '../../enrollment/gql/employeeEnrollmentInfo_gen'
import { TelephoneFragment } from '../../graphql/fragments/TelephoneFragment_gen'
import { EmployeeEnrollmentRequirementStatus } from '../../graphql/generated.types'
import { SendPhoneVerificationForm } from './SendPhoneVerificationForm'
import { VerifyPhoneForm } from './VerifyPhoneForm'

const ConfirmPhoneForm = ({ tel }: { tel: TelephoneFragment }) => {
  const [displayedForm, setDisplayedForm] = React.useState<'verify' | 'resend'>('verify')
  if (displayedForm === 'verify') {
    return <VerifyPhoneForm tel={tel} onNotReceived={() => setDisplayedForm('resend')} />
  }
  if (displayedForm === 'resend') {
    return <SendPhoneVerificationForm tel={tel} onDone={() => setDisplayedForm('verify')} resend />
  }
  return null
}

const SendConfirmationCodeForm = ({ tel }: { tel: TelephoneFragment | undefined | null }) => {
  return <SendPhoneVerificationForm tel={tel} onDone={() => undefined} />
}

/**
 * This dialog should be rendered anywhere a user sets/updates their phone number.
 *
 * It will _not_ display unless a verification code has been sent to the user's phone number,
 * but it does have the ability to re-send the code if the user has not received it or they
 * want the code sent to a different number.
 *
 * The component will _only_ ensure that the user's phone number is confirmed. It does not expose
 * any callbacks. The parent should check for `currentUser.tel.isSmsConfirmed` from the GraphQL
 * API in order to continue.
 */
export const VerifyPhoneDialog = ({ onClose }: { onClose?: () => void }) => {
  const { data: employeeEnrollmentInfo } = useEmployeeEnrollmentInfoQuery()
  const tel = employeeEnrollmentInfo?.currentUser?.tel
  const isConfirmationCodeSent = tel?.isConfirmationCodeSent
  const enrollmentInfo = employeeEnrollmentInfo?.employee?.enrollmentInfo
  const isPhoneVerified =
    enrollmentInfo?.requirements.phoneVerification === EmployeeEnrollmentRequirementStatus.Complete

  const showForm = Boolean(isConfirmationCodeSent && !isPhoneVerified)

  return (
    <FullDialog
      open={showForm}
      onClose={onClose}
      title={
        <Typography variant="h2">
          <FormattedMessage id="enroll.phone_verification.verify_your_phone_number" />
        </Typography>
      }
    >
      <Container>
        {isConfirmationCodeSent ? <ConfirmPhoneForm tel={tel} /> : <SendConfirmationCodeForm tel={tel} />}
      </Container>
    </FullDialog>
  )
}
