import { getGqlInputErrorHandler, GqlInputErrorException } from '@alice-financial/api'
import { ActionLayout, Box, Button, Link, PinInputController, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { prettyTelString } from '../../../utils/formatters/telFormat'
import { TelephoneFragment } from '../../graphql/fragments/TelephoneFragment_gen'
import { VerifyPhoneInput } from '../../graphql/generated.types'
import { useSubmitPhoneVerification } from '../useSubmitPhoneVerification'

type VerifyPhoneFormProps = {
  tel: TelephoneFragment | undefined | null
  onNotReceived: () => void
}

/**
 * This is two forms in one:
 * 1. A form to submit the verification code
 * 2. A form to re-send the verification code, optionally to a new phone number
 */
export const VerifyPhoneForm = ({ tel, onNotReceived }: VerifyPhoneFormProps) => {
  const intl = useIntl()
  const { handleSubmit, control, setError } = useForm<VerifyPhoneInput>({
    defaultValues: { confirmationCode: '' },
  })
  const gqlInputErrorHandler = getGqlInputErrorHandler(setError)

  const {
    mutate: onSubmitVerification,
    isLoading: isSubmittingVerification,
    isSuccess: isSuccessfulVerification,
  } = useSubmitPhoneVerification({
    onError: (err) => {
      if (err instanceof GqlInputErrorException) gqlInputErrorHandler(err)
    },
  })

  const submitCodeDisabled = isSubmittingVerification || isSuccessfulVerification

  const onSubmit = handleSubmit(({ confirmationCode }) => onSubmitVerification({ confirmationCode }))

  return (
    <form data-testid="verify-phone" onSubmit={onSubmit}>
      <Typography variant="body2">
        {tel && (
          <FormattedMessage
            id="enroll.phone_verification.texted_you_a_verification_code"
            values={{
              tel: (
                <Link onClick={onNotReceived} to={'#'}>
                  {prettyTelString(tel.nationalNumber)}
                </Link>
              ),
            }}
          />
        )}
      </Typography>
      <Box mb={2} mt={1} textAlign="center">
        <PinInputController
          control={control}
          name="confirmationCode"
          helperText={intl.formatMessage({ id: 'enroll.phone_verification.enter_the_4_digit_code' })}
          autoFocus
          rules={{
            validate: (value) => {
              if (value) return
              return intl.formatMessage(
                {
                  id: 'enroll.phone_verification.code_sent_to_tel',
                },
                { tel: tel ? prettyTelString(tel.nationalNumber) : '' }
              )
            },
          }}
        />
      </Box>

      <ActionLayout
        primary={
          <Button
            variant="contained"
            type="submit"
            data-testid="submit-verify-phone"
            disabled={submitCodeDisabled}
            fullWidth
          >
            <FormattedMessage id="common.confirm" />
          </Button>
        }
        secondary={
          <Button variant="outlined" fullWidth color="secondary" onClick={onNotReceived}>
            <FormattedMessage id="enroll.phone_verification.did_not_receive_label" />
          </Button>
        }
      />
    </form>
  )
}
