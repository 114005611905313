import { UseGqlMutationCallbacks, useMutationWithInvalidation } from '@alice-financial/api'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { UserSignupInput } from '../../graphql/generated.types'
import { UserSignupMutation, useUserSignupMutation } from '../../user/gql/userSignup_gen'

export type AdminSignupFormValues = UserSignupInput & {
  acceptLegal: boolean // not currently supported by the API
  acceptEmployerTos: boolean // not currently supported by the API
}

type UseSignupMutationOptions = UseGqlMutationCallbacks<UserSignupMutation, AdminSignupFormValues>
export const useSignup = (mutationOptions?: UseSignupMutationOptions) => {
  const { mutateAsync: signup } = useUserSignupMutation()
  const mutationOptionsWithNotifs = useMutationNotifier({}, mutationOptions)
  return useMutationWithInvalidation(
    ({ acceptLegal, acceptEmployerTos, ...input }) => signup({ input }),
    mutationOptionsWithNotifs,
    'all' // effectively a login - reset all queries
  )
}
