import * as Types from '../../graphql/generated.types'

import {
  GqlUserFragmentDoc,
  FormsSubmissionFragmentDoc,
  FormsQuestionFragmentDoc,
  FormsAnswerFragmentDoc,
} from './currentUser_gen'
import { TelephoneFragmentDoc } from '../../graphql/fragments/TelephoneFragment_gen'
import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UserSignupMutationVariables = Types.Exact<{
  input: Types.UserSignupInput
}>

export type UserSignupMutation = {
  __typename?: 'Mutation'
  userSignup?: {
    __typename?: 'UserSignupPayload'
    currentUser?: {
      __typename?: 'User'
      id: number
      firstName?: string | null
      lastName?: string | null
      preferredName?: string | null
      email?: string | null
      languagePreference: Types.LanguagePreference
      birthdate?: string | null
      isEmailConfirmed: boolean
      hasDummyPassword: boolean
      helpScoutSecureBeaconToken?: string | null
      access: { __typename?: 'FeatureAccess'; orgAdminDashboard: Types.AccessRequirementEnum }
      tel?: {
        __typename?: 'Telephone'
        nationalNumber: string
        isSmsConfirmed?: boolean | null
        isConfirmationCodeSent?: boolean | null
      } | null
      provisionalOrganizations: Array<{ __typename?: 'ProvisionalOrganization'; id: number }>
      formSubmissions?: Array<{
        __typename?: 'FormsSubmission'
        id: number
        status?: string | null
        subjectType: string
        subjectId: number
        ownerId?: number | null
        ownerType?: string | null
        template: {
          __typename?: 'FormsTemplate'
          id: number
          displayOrder: Array<number>
          templateType: string
        }
        questions: Array<{
          __typename?: 'FormsQuestion'
          id: number
          options?: Array<string> | null
          questionType: Types.QuestionType
          text: string
          subtitle?: string | null
          placeholder?: string | null
        }>
        answers: Array<{ __typename?: 'FormsAnswer'; id: number; questionId: number; text?: any | null }>
      }> | null
      roles: Array<{ __typename?: 'Role'; id: number }>
    } | null
    inputErrors: Array<{ __typename?: 'InputError'; argument: string; message: string }>
  } | null
}

export const UserSignupDocument = `
    mutation UserSignup($input: UserSignupInput!) {
  userSignup(input: $input) {
    currentUser {
      ...GqlUser
    }
    inputErrors {
      argument
      message
    }
  }
}
    ${GqlUserFragmentDoc}
${TelephoneFragmentDoc}
${FormsSubmissionFragmentDoc}
${FormsQuestionFragmentDoc}
${FormsAnswerFragmentDoc}`

export const useUserSignupMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<UserSignupMutation, TError, UserSignupMutationVariables, TContext>
) => {
  return useMutation<UserSignupMutation, TError, UserSignupMutationVariables, TContext>(
    ['UserSignup'],
    (variables?: UserSignupMutationVariables) =>
      fetchGql<UserSignupMutation, UserSignupMutationVariables>(UserSignupDocument, variables)(),
    options
  )
}
