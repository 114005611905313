import * as Types from '../../../graphql/generated.types'

import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { fetchGql } from '@alice-financial/api'
export type UserHelpRequestMutationVariables = Types.Exact<{
  input: Types.UserHelpRequestInput
}>

export type UserHelpRequestMutation = {
  __typename?: 'Mutation'
  userHelpRequest?: { __typename?: 'UserHelpRequestPayload'; success: boolean } | null
}

export const UserHelpRequestDocument = `
    mutation UserHelpRequest($input: UserHelpRequestInput!) {
  userHelpRequest(input: $input) {
    success
  }
}
    `

export const useUserHelpRequestMutation = <TError = Error, TContext = unknown>(
  options?: UseMutationOptions<UserHelpRequestMutation, TError, UserHelpRequestMutationVariables, TContext>
) => {
  return useMutation<UserHelpRequestMutation, TError, UserHelpRequestMutationVariables, TContext>(
    ['UserHelpRequest'],
    (variables?: UserHelpRequestMutationVariables) =>
      fetchGql<UserHelpRequestMutation, UserHelpRequestMutationVariables>(
        UserHelpRequestDocument,
        variables
      )(),
    options
  )
}
