import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import type {} from '@mui/x-data-grid/themeAugmentation'
import * as React from 'react'
// import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import { alpha, Components, CSSInterpolation, Slide, Theme } from '@mui/material'
import { StepIcon } from '../navigation'
import { palette } from './palette'
import { insetShadow } from './styleUtils'

const inputLayoutStyles: CSSInterpolation = {
  padding: '0 0.1rem',
}

export const FOOTER_MAX_HEIGHT = 250

export const components: Components<Theme> = {
  MuiAccordion: {
    defaultProps: {
      elevation: 3,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        overflow: 'hidden',
        '&.Mui-expanded': {
          borderLeft: `4px solid ${theme.palette.primary.main}`,
        },
      }),
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: ({ theme }) => ({
        paddingBottom: theme.spacing(3),
      }),
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0, 1),
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }),
      content: ({ theme }) => ({
        '&.Mui-expanded': {
          margin: theme.spacing(1.5, 0),
        },
      }),
    },
  },
  MuiAlert: {
    defaultProps: {
      iconMapping: {
        success: <CheckCircleIcon color="primary" fontSize="large" />,
        info: <InfoIcon fontSize="large" />,
        warning: <WarningAmberIcon color="warning" fontSize="large" />,
        error: <ErrorIcon color="error" fontSize="large" />,
      },
    },
    styleOverrides: {
      root: ({ theme, ownerState: { severity } }) => ({
        marginBottom: theme.spacing(2),
        padding: theme.spacing(1, 1.5),
        alignItems: 'flex-start',
        backgroundColor:
          (severity === 'warning' && alpha(theme.palette.warning.main, theme.palette.action.hoverOpacity)) ||
          (severity === 'info' && theme.palette.background.default) ||
          undefined,
        borderLeft: severity === 'info' ? `4px solid ${theme.palette.primary.main}` : undefined,
        borderRadius: severity === 'info' ? 0 : theme.shape.borderRadius,
      }),
      message: {
        padding: 0,
        lineHeight: 1.2,
        fontSize: '1rem',
        overflow: 'visible',
      },
      action: ({ theme }) => ({
        paddingTop: 0,
        paddingLeft: theme.spacing(),
        marginRight: 0,
      }),
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        lineHeight: 1,
        fontSize: '1.1rem',
        fontWeight: 'bold',
        margin: '0.2em 0 0.5em',
      },
    },
  },
  MuiAppBar: {
    defaultProps: {
      elevation: 2,
    },
    styleOverrides: {
      root: ({ theme, ownerState: { color } }) => ({
        '@media all': {
          transition: theme.transitions.create(['background-color', 'transform']),
          backgroundColor:
            ((color === 'primary' || color === 'info') && theme.palette[color]?.main) ||
            theme.palette.background.default,
        },
      }),
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      root: {
        display: 'inline-block',
        margin: '0 0.3rem 0 -0.3rem',
        minWidth: '10rem',
        maxWidth: '100%',
        '& .MuiInput-root .MuiInput-input': {
          ...inputLayoutStyles,
        },
        '&.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot': {
          paddingRight: '40px !important', // avoid excessive space for clearIcon
        },
      },
      input: {
        ...inputLayoutStyles,
      },
      clearIndicator: {
        top: 0,
      },
      popupIndicator: {
        padding: 0,
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 30,
        width: 30,
        fontSize: `12px !important`,
        fontWeight: 'bold',
        backgroundColor: theme.palette.primary.main,
      }),
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        whiteSpace: 'nowrap',
        fontSize: '0.8rem',
      },
      ol: {
        display: 'flex',
        flexWrap: 'nowrap',
      },
      separator: ({ theme }) => ({
        color: theme.palette.text.disabled,
      }),
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
        textTransform: 'none',
      },
      endIcon: {
        '& .MuiSvgIcon-fontSizeLarge': {
          fontSize: 32,
        },
      },
      sizeLarge: ({ theme }) => ({
        padding: theme.spacing(1.1, 3),
        fontSize: '1.2rem',
        '& .MuiButton-endIcon': {
          marginRight: 0,
        },
      }),
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: ({ theme }) => ({
        alignSelf: 'flex-start', // when the label is long, the checkbox should remain aligned to first line
        '& + .MuiFormControlLabel-label': {
          padding: theme.spacing(1, 0),
        },
        '& .MuiSvgIcon-fontSizeMedium.MuiSvgIcon-root': {
          fontSize: '2.2rem',
        },
      }),
    },
  },
  MuiChip: {
    styleOverrides: {
      // these styles are optimized for ToggleChip - if we need other Chips with smaller
      // proportions, either use `<Chip size="small" />` or use `styled` to apply these
      // styles to ToggleChip only.
      root: {
        borderRadius: '21px !important',
        border: '1px solid',
        '&.toggleChip': {
          borderColor: 'rgba(192, 192, 192, 0.5)',
          backgroundColor: 'transparent',
        },
      },
      colorPrimary: ({ theme }) => ({
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.light, theme.palette.action.activatedOpacity),
        borderColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, theme.palette.action.disabledOpacity),
        },
      }),
      colorSecondary: ({ theme }) => ({
        color: theme.palette.secondary.main,
        backgroundColor: alpha(theme.palette.secondary.light, theme.palette.action.activatedOpacity),
        borderColor: theme.palette.secondary.main,
        '&:hover': {
          backgroundColor: alpha(theme.palette.secondary.light, theme.palette.action.disabledOpacity),
        },
      }),
      colorError: ({ theme }) => ({
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.light, theme.palette.action.activatedOpacity),
        borderColor: theme.palette.error.main,
        '&:hover': {
          backgroundColor: alpha(theme.palette.error.light, theme.palette.action.disabledOpacity),
        },
      }),
      colorInfo: ({ theme }) => ({
        color: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.light, theme.palette.action.activatedOpacity),
        borderColor: theme.palette.info.main,
        '&:hover': {
          backgroundColor: alpha(theme.palette.info.light, theme.palette.action.disabledOpacity),
        },
      }),
      label: {
        fontWeight: 'bold',
        fontSize: '0.9rem',
      },
      icon: {
        fontSize: '1.2em', // smaller than default 1.5em
        marginLeft: '8px', // bumped up from default 5x to compensate for smaller fontSize
        marginRight: '-8px', // bumped up from default -6px to compensate for smaller fontSize
      },
      sizeSmall: {
        height: 22,
        fontSize: '0.8rem',
      },
    },
  },
  MuiCssBaseline: {
    styleOverrides: {
      // These styles ensure that the displayed app will fill the vertical viewport, following
      // https://stackoverflow.com/questions/6654958/make-body-have-100-of-the-browser-height/38908284#38908284
      html: {
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        display: 'table',
        '&#plaid-link-temporary-id': {
          display: 'block', // Plaid IFrame doesn't play nicely with display: table, but it provides an ID to inject override
        },
        '@media print': {
          fontSize: '12px', // smaller than default 16px so that printed pages don't have huge text
        },
      },
      body: {
        backgroundColor: 'white',
        width: '100%',
        display: 'table-cell',
        '#plaid-link-temporary-id &': {
          display: 'block', // Plaid IFrame doesn't play nicely with display: table-cell
        },
      },
      input: {
        padding: '12px 16px',
        display: 'block',
        width: '100%',
        border: `1px solid ${palette.primary.main}`,
        borderRadius: '4px',
        boxShadow: 'none',
        fontSize: '1.2rem',
        color: palette.text.primary,
        outlineColor: `${palette.primary.dark}`,
        '&:active,&:focus,&:hover': {
          borderColor: palette.primary.dark,
        },
      },
      ul: {
        paddingInlineStart: '1.5rem',
      },
      '.pac-container': {
        zIndex: '9999 !important',
      },
      '#app': {
        minHeight: '100%',
        position: 'relative',
      },
      'iframe[title="Button to launch messaging window"]': {
        // Zendesk Web Widget - can't hide programmatically so have to hide with CSS
        '@media print': {
          display: 'none',
        },
      },
    },
  },
  MuiContainer: {
    styleOverrides: {
      root: ({ theme }) => {
        const padding = theme.spacing(0, 2)

        return {
          maxWidth: '48rem',
          padding,
          [theme.breakpoints.up('lg')]: { maxWidth: '48rem' },
          [theme.breakpoints.up('md')]: { padding },
          [theme.breakpoints.up('sm')]: { padding },
        }
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      root: {
        border: 'none',
      },
      columnHeaderTitle: {
        fontWeight: 'bold',
      },
      columnHeader: ({ theme }) => ({
        padding: theme.spacing(0, 1),
        borderBottom: '2px solid',
      }),
      row: ({ theme }) => ({
        ':hover': {
          backgroundColor: alpha(theme.palette.action.disabled, theme.palette.action.hoverOpacity),
        },
      }),
      cell: ({ theme }) => ({
        padding: theme.spacing(0, 1),
        fontSize: '0.9rem',
      }),
      toolbarContainer: {
        justifyContent: 'space-between',
        alignItems: 'flex-start',
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme, ownerState: { fullScreen } }) => ({
        margin: fullScreen ? 0 : theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        minWidth: '30%',
        '& .MuiDialogTitle-root': fullScreen
          ? {
              padding: theme.spacing(4, 8, 2, 2),
              textAlign: 'center',
              fontSize: theme.typography.h1.fontSize,
            }
          : {
              padding: theme.spacing(2, 8, 2, 2), // space for 'close' button
              fontSize: '1em',
            },
      }),
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: ({ theme }) => ({ padding: theme.spacing(1) }),
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: { padding: 0 },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        margin: 0,
        lineHeight: 1,
      },
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        fontWeight: 'bold',
      },
    },
  },
  MuiFormControl: {
    defaultProps: {
      variant: 'standard',
    },
    styleOverrides: {
      root: {
        maxWidth: '100%',
      },
    },
  },
  MuiFormControlLabel: {
    defaultProps: {
      slotProps: { typography: { variant: 'body2' } },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
        marginTop: 0,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        '& .MuiTextField-root': {
          marginTop: 0,
        },
      }),
      focused: ({ theme }) => ({
        color: theme.palette.primary.dark,
      }),
    },
  },
  MuiIconButton: {
    styleOverrides: {
      sizeLarge: {
        padding: 8,
        '& .MuiSvgIcon-root': {
          height: '1.4em',
          width: '1.4em',
        },
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: '0 0.1rem',
        minWidth: '5rem',
        maxWidth: '100%',
        padding: 0,
        color: theme.palette.primary.main,
        '&.Mui-focused': {
          color: theme.palette.secondary.main,
        },
        '&.Mui-error': {
          color: theme.palette.error.main,
          '& + .MuiFormHelperText-root': {
            '&.Mui-error': {
              background: theme.palette.background.default,
              margin: '-3px 2px',
              marginBottom: '-10rem', // allow surrounding content to flow underneath
              padding: '0 0.5rem',
              boxShadow: theme.shadows[3],
              zIndex: theme.zIndex.tooltip,
            },
          },
        },
      }),
      input: {
        ...inputLayoutStyles,
        '&[type="number"]': {
          textAlign: 'right',
          padding: 0,
        },
      },
      underline: ({ theme }) => ({
        '&:before': {
          borderBottom: `2px dashed ${theme.palette.primary.main}`,
          bottom: 3,
        },
        '&:hover:before, &.Mui-focused:after': {
          borderBottomColor: `${theme.palette.secondary.main} !important`,
          bottom: 3,
        },
        '&.Mui-error:hover:before, &.Mui-error:after': {
          borderBottomColor: `${theme.palette.error.main} !important`,
          bottom: 3,
        },
      }),
    },
  },
  MuiInputLabel: {
    defaultProps: { shrink: true },
    styleOverrides: {
      root: {
        fontSize: '1.4rem',
        top: -2,
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: ({ theme }) => ({
        transition: theme.transitions.create('color'),
        '&:hover:not(.MuiButton-root)': {
          color: theme.palette.primary.dark,
        },
        '.Mui-error &': {
          color: theme.palette.error.main,
        },
      }),
    },
  },
  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 3,
        backgroundColor: 'transparent',
      },
    },
  },
  MuiList: {
    styleOverrides: {
      root: {
        '& ul': {
          padding: 0,
        },
      },
    },
  },
  MuiListItem: {
    defaultProps: {
      alignItems: 'flex-start',
    },
    styleOverrides: {
      root: ({ theme }) => ({
        columnGap: theme.spacing(1),
      }),
      padding: ({ theme }) => ({
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.75),
      }),
      gutters: ({ theme }) => ({
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
      }),
      divider: {
        '&:last-of-type': {
          borderBottom: 'none',
        },
      },
    },
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        display: 'flex',
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme, ownerState: { color } }) => ({
        columnGap: theme.spacing(1),
        justifyContent: 'space-between',
        color: color || theme.palette.primary.main,
        transition: theme.transitions.create('background-color'),
        backgroundColor: color ? alpha(color, theme.palette.action.hoverOpacity) : undefined,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        '&:hover': {
          color: `${color || theme.palette.primary.main} !important`,
          backgroundColor: color
            ? alpha(color, theme.palette.action.hoverOpacity + 0.1)
            : alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity),
        },
        '& .MuiListItemText-primary': {
          fontWeight: 'bold',
        },
      }),
      dense: ({ theme }) => ({
        paddingTop: theme.spacing(0.8),
        paddingBottom: theme.spacing(0.8),
      }),
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        justifyContent: 'center',
        minWidth: '2.5rem',
      },
    },
  },
  MuiListItemSecondaryAction: {
    styleOverrides: {
      root: ({ theme }) => ({
        right: theme.spacing(2),
      }),
    },
  },
  MuiListItemText: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: theme.spacing(0.4, 0),
        '& .MuiTypography-root': {
          lineHeight: 1,
          '&.MuiTypography-gutterBottom': { marginBottom: '0.7em', marginTop: '0.2em' },
        },
      }),
      primary: ({ theme }) => ({
        lineHeight: 1,
        color: theme.palette.text.secondary,
        flexWrap: 'wrap',
        display: 'flex',
        alignItems: 'baseline',
        columnGap: theme.spacing(0.5),
      }),
      secondary: ({ theme }) => ({
        marginTop: 1,
        color: theme.palette.text.secondary,
        fontSize: '1rem',
        lineHeight: 1.2,
      }),
      multiline: {
        '& .MuiTypography-caption': {
          marginTop: '0.2em',
        },
      },
      dense: {
        margin: 0,
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.primary.contrastText,
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1,
        paddingTop: theme.spacing(),
        paddingBottom: theme.spacing(),
        minHeight: theme.spacing(6),
        border: `1px solid rgba(192, 192, 192, 0.3)`,
        borderWidth: '1px 0',
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[1],
        '& .MuiButtonBase-root': {
          color: theme.palette.primary.contrastText,
          boxShadow: 'none',
          '&.Mui-disabled': {
            color: alpha(theme.palette.primary.contrastText, 0.3),
          },
          '&:hover': {
            boxShadow: 'none',
          },
        },
        '& .MuiTypography-root': {
          lineHeight: 1,
        },
      }),
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.light, theme.palette.action.disabledOpacity),
        },
      }),
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: ({ theme }) => ({
        borderColor: theme.palette.primary.main,
        '& legend': { maxWidth: 'none', fontSize: '1.08rem' }, // because 'shrink' is true by default
      }),
      root: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.primary.dark,
        },
      }),
      input: ({ theme }) => ({
        padding: theme.spacing(1.5, 2),
      }),
      multiline: {
        padding: 0,
        fontSize: '1rem',
      },
      inputSizeSmall: ({ theme }) => ({
        padding: theme.spacing(1, 1.5),
        fontSize: '1rem',
      }),
    },
  },
  MuiPaper: {
    styleOverrides: { root: { overflow: 'hidden' } },
  },
  MuiRadio: {
    styleOverrides: {
      root: ({ theme }) => ({
        alignSelf: 'flex-start', // when the label is long, the radio should remain aligned to first line
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(0.5),
      }),
    },
  },
  MuiSelect: {
    defaultProps: {
      variant: 'standard',
      native: true,
    },
  },
  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      TransitionComponent: Slide,
    },
    styleOverrides: {
      root: {
        left: 0,
        right: 0,
        top: 0,
        '& > .MuiPaper-root': {
          width: '100%',
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: ({ theme }) => ({
        top: 'calc(1rem - 1.5px)', // half height of MuiStepLabel-iconContainer minus half borderTopWidth
        left: 'calc(-50% + 1rem)', // adjusted by 1/2 width of MuiStepLabel-iconContainer
        right: 'calc(50% + 1rem)',
        zIndex: 0,
        '&.Mui-active, &.Mui-completed': {
          '& .MuiStepConnector-line': {
            borderColor: theme.palette.primary.main,
          },
        },
      }),
      line: ({ theme }) => ({
        borderColor: theme.palette.action.disabled,
        borderTopWidth: 3,
        borderRadius: 1,
      }),
    },
  },
  MuiStepLabel: {
    defaultProps: {
      StepIconComponent: StepIcon,
    },
    styleOverrides: {
      root: {
        '&:hover .MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
          opacity: 1,
        },
      },
      iconContainer: {
        width: '2rem',
        height: '2rem',
      },
      label: ({ theme }) => ({
        '&.MuiStepLabel-alternativeLabel': {
          fontSize: '0.8rem',
          position: 'absolute',
          top: '-2.5rem',
          left: 0,
          width: '100%',
          opacity: 0,
          transition: theme.transitions.create('opacity'),
        },
      }),
    },
  },
  MuiStepper: {
    defaultProps: {
      alternativeLabel: true,
    },
    styleOverrides: {
      root: {
        margin: '0 auto', // should generally be centered
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      switchBase: ({ theme }) => ({
        '&.Mui-checked': {
          color: theme.palette.primary.light,
          '& + .MuiSwitch-track': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
      }),
      track: ({ theme }) => ({
        boxShadow: insetShadow(theme.shadows[3]),
      }),
      thumb: ({ theme }) => ({
        boxShadow: theme.shadows[3],
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        fontSize: '1rem',
        verticalAlign: 'top',
      },
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: {
        '& .MuiTableRow-root': {
          borderBottom: '2px solid',
        },
        '& .MuiTableCell-root': {
          fontSize: '0.9rem',
          fontWeight: 'bold',
          fontVariant: 'small-caps',
          paddingBottom: '0.1em',
          whiteSpace: 'nowrap',
        },
      },
    },
  },
  MuiTextField: {
    defaultProps: {
      variant: 'outlined',
    },
    styleOverrides: {
      root: ({ ownerState }) => ({
        marginTop: ownerState.label && ownerState.variant === 'outlined' ? '0.5em' : 0,
        marginBottom: '0.5em',
        '&:after': {
          ...inputLayoutStyles,
          padding: '0 0.7rem',
          position: 'relative',
          marginBottom: '-2em',
          content: "attr(data-value) ' '", // dynamically set to input value - allows input to expand to fit content
          visibility: 'hidden',
          whiteSpace: 'pre-wrap',
        },
        '& > .MuiFormHelperText-root': {
          marginLeft: '0.1em',
        },
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(0.5, 1),
        textTransform: 'none',
        '&.Mui-selected': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          '&.Mui-disabled': {
            backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
            color: theme.palette.primary.contrastText,
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.dark,
          },
        },
        '&:hover': {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
        },
      }),
    },
  },
  MuiToolbar: {
    styleOverrides: {
      root: {
        alignItems: 'center',
      },
      gutters: ({ theme }) => ({
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        'form &': {
          lineHeight: 1.5,
        },
        'legend&': {
          lineHeight: 1.2,
          marginBottom: '0.5rem',
        },
        '& .MuiTextField-root': {
          marginTop: 0,
        },
      },
      h1: ({ theme }) => ({
        lineHeight: 1, // tighter line height for headings
        marginBottom: '0.2em', // add a little minimum space below headings
        '& strong': {
          color: theme.palette.primary.main,
          fontWeight: 900,
        },
        '&.MuiTypography-gutterBottom': { marginBottom: '0.5em' },
      }),
      h2: ({ theme }) => ({
        marginBottom: '0.2em',
        '& strong': { color: theme.palette.primary.main },
        '&.MuiTypography-gutterBottom': { marginBottom: '0.5em' },
      }),
      h3: ({ theme }) => ({
        color: theme.palette.text.secondary,
        '& strong': { color: theme.palette.primary.main },
        '&.MuiTypography-gutterBottom': { marginBottom: '0.5em' },
      }),
      h4: {
        '&.MuiTypography-gutterBottom': { marginBottom: '0.5em' },
      },
      overline: {
        fontWeight: 'bold',
      },
      gutterBottom: {
        marginBottom: '1em',
        'legend&': {
          marginBottom: '1em',
        },
      },
    },
  },
}
