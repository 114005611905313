import { useNavigate } from 'react-router'
import {
  BankingAccountPlatform,
  BankingConnectionStatus,
  BankingInstitutionPlaform,
} from '../../../../graphql/generated.types'
import { BankingConnectionFragment } from '../../gql/connectedCardsQuery_gen'
import { useNavigateToPlaidLink } from './usePlaidLink'

type RoutableInstitution = {
  platform: BankingInstitutionPlaform
  preferredPlatform?: BankingInstitutionPlaform | null
  unifiedInstitutionId?: number | null
}

export const UNSUPPORTED_UNIFIED_INSTITUTION = 'unsupported'
const RECONNECTABLE_STATUSES = [BankingConnectionStatus.ConnectionError]
export const CONNECTION_ERROR_STATUSES = [BankingConnectionStatus.ConnectionError]
const DISCONNECTED_STATUSES = [
  BankingConnectionStatus.Disconnecting,
  BankingConnectionStatus.SystemDisconnected,
  BankingConnectionStatus.CustomerDisconnected,
  BankingConnectionStatus.AliceDisconnected,
]
// consider personal card 'connected' if it's pending or connected - user can't do anything more in the pending state anyway
const CONNECTED_STATUSES = [BankingConnectionStatus.Pending, BankingConnectionStatus.Connected]
export const isConnected = (conn: Pick<BankingConnectionFragment, 'status'>) =>
  CONNECTED_STATUSES.includes(conn.status)
// note that `isAvailable` is not the same as `isConnected` - connections with errors are not considered 'connected', but are available for user interaction
export const isAvailable = (conn: Pick<BankingConnectionFragment, 'status'>) =>
  !DISCONNECTED_STATUSES.includes(conn.status)
export const canBeReconnected = (status: BankingConnectionStatus) => RECONNECTABLE_STATUSES.includes(status)
export const isInErrorState = (status: BankingConnectionStatus) => CONNECTION_ERROR_STATUSES.includes(status)

const getInstitutionDetailsPath = (unifiedInstitutionId?: number | null): string =>
  `/cards/personal/${unifiedInstitutionId || UNSUPPORTED_UNIFIED_INSTITUTION}`
const getPlatformDetailsPath = (platform: BankingAccountPlatform): string => `/cards/personal/${platform}`
const getUnsupportedDetailsPath = (): string => getInstitutionDetailsPath()

export const getDetailsPath = (connection: BankingConnectionFragment): string => {
  if (connection.institution?.unifiedInstitutionId) {
    return getInstitutionDetailsPath(connection.institution.unifiedInstitutionId)
  }
  if (connection.platform) {
    return getPlatformDetailsPath(connection.platform)
  }
  return getUnsupportedDetailsPath()
}
export const getConnectPlatformPath = (platform: BankingAccountPlatform): string => `connect/${platform}`
export const getConnectInstitutionPath = (institution: RoutableInstitution): string =>
  `${getInstitutionDetailsPath(institution.unifiedInstitutionId)}/connect/${
    institution.preferredPlatform || institution.platform
  }`
export const getConnectPath = (connection: BankingConnectionFragment): string => {
  if (connection.institution?.unifiedInstitutionId) {
    return getConnectInstitutionPath(connection.institution)
  }
  if (connection.platform) {
    return getConnectPlatformPath(connection.platform)
  }
  return 'connect'
}

export const getIsConnectedToInstitution =
  (unifiedBankingInstitutionId?: string | number) => (bankingConnection: BankingConnectionFragment) =>
    Boolean(
      unifiedBankingInstitutionId &&
        isConnected(bankingConnection) &&
        bankingConnection.institution?.unifiedInstitutionId?.toString() ===
          unifiedBankingInstitutionId.toString()
    )

export const getIsLinkedToInstitution =
  (unifiedBankingInstitutionId?: string | number) => (bankingConnection: BankingConnectionFragment) =>
    Boolean(
      unifiedBankingInstitutionId &&
        bankingConnection.institution?.unifiedInstitutionId?.toString() ===
          unifiedBankingInstitutionId.toString()
    )

/**
 * A hook that returns a callback that can be used to start a reconnect flow for the provided connection.
 * Finicity connections will navigate to the institution connection page, while Plaid connections will navigate to the Plaid Link flow.
 */
export const useReconnect = () => {
  const navigate = useNavigate()
  const navigateToPlaidLink = useNavigateToPlaidLink()
  return (connection: BankingConnectionFragment) => {
    const institution = connection.institution
    if (!institution) return null // connections that can reconnect always have an `institution`
    const platform = institution.preferredPlatform || institution.platform
    if (platform === BankingInstitutionPlaform.Finicity) {
      return navigate(getConnectInstitutionPath(institution))
    }
    navigateToPlaidLink({ existingConnectionId: connection.id })
  }
}
