import { HealthcheckItem, HealthcheckItemStatus, List } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { IntlShape, useIntl } from 'react-intl'
import {
  BankCardType,
  BankingAccountPlatform,
  BankingConnectionStatus,
} from '../../../graphql/generated.types'
import { BankingConnectionFragment } from '../gql/connectedCardsQuery_gen'
import { platformDisplayName } from '../spendingConnectionUtils'
import { getDetailsPath, isAvailable, isInErrorState } from './connect/bankingConnectionUtils'
import { getOMNYStatusLabel } from './connect/omny/OMNYConnectionDetail'

const getPersonalCardLabel = (bankingConnection: BankingConnectionFragment, intl: IntlShape) => {
  const { institution, status, bankingAccounts } = bankingConnection
  if (status === BankingConnectionStatus.Pending) {
    return intl.formatMessage({ id: 'cards.personal.status.pending' })
  }
  if (!institution) return ''

  const cardTypeCount = bankingAccounts.reduce(
    (acc, account) => {
      if (account.cardType === BankCardType.Credit) acc.credit++
      if (account.cardType === BankCardType.Debit) acc.debit++
      return acc
    },
    { debit: 0, credit: 0 }
  )

  if (cardTypeCount.credit === 0) {
    return intl.formatMessage(
      { id: 'cards.personal.debit_only_account' },
      { bankAccountsCount: bankingAccounts.length, debitCount: cardTypeCount.debit }
    )
  } else {
    return intl.formatMessage(
      { id: 'cards.personal.debit_and_credit_accounts' },
      {
        bankAccountsCount: bankingAccounts.length,
        debitCount: cardTypeCount.debit,
        creditCount: cardTypeCount.credit,
      }
    )
  }
}

const getLabel = (bankingConnection: BankingConnectionFragment, intl: IntlShape) => {
  const { status, platform } = bankingConnection
  if (isInErrorState(status)) return intl.formatMessage({ id: 'cards.personal.connection_error_retry' })
  if (platform === BankingAccountPlatform.Omny) return getOMNYStatusLabel(bankingConnection, intl)

  return getPersonalCardLabel(bankingConnection, intl)
}

const getItemStatus = (connectionStatus: BankingConnectionStatus): HealthcheckItemStatus => {
  if (connectionStatus === BankingConnectionStatus.Pending) return 'pending'
  if (connectionStatus === BankingConnectionStatus.ConnectionError) return 'incomplete'
  if (connectionStatus === BankingConnectionStatus.Connected) return 'complete'
  return 'incomplete' // card is disconnected - generally shouldn't be shown to user
}

type BankingConnectionItemProps = {
  bankingConnection: BankingConnectionFragment
}
const BankingConnectionItem = ({ bankingConnection }: BankingConnectionItemProps) => {
  const intl = useIntl()
  const { institution, status } = bankingConnection
  const itemStatus = getItemStatus(status)
  const name =
    institution?.name || (bankingConnection.platform && platformDisplayName[bankingConnection.platform]) || ''
  return (
    <HealthcheckItem
      to={getDetailsPath(bankingConnection)}
      status={itemStatus}
      primary={name}
      secondary={getLabel(bankingConnection, intl)}
    />
  )
}

type BankingConnectionListProps = {
  bankingConnections: Array<BankingConnectionFragment>
}
/**
 * List of connected banks - when a bank is disconnected for any reason, it will not be shown in
 * this list.
 */
export const BankingConnectionList = ({ bankingConnections }: BankingConnectionListProps) => {
  const listedConnections = bankingConnections.filter(isAvailable)
  return (
    <List data-testid="current-banking-connections">
      {listedConnections.map((connection) => (
        <BankingConnectionItem key={connection.id} bankingConnection={connection} />
      ))}
    </List>
  )
}
