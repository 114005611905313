import { Box, Divider, ExternalLink, Typography, useResponseNotification } from '@alice-financial/pretext-ui'

import * as React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHelpScout } from '../auth/helpScout/beacon'
import { useAnalytics } from '../telemetry/AnalyticsProvider'
import { UserHelpRequestForm } from '../user/helpRequest/UserHelpRequestForm'

export const useGetHelp = () => {
  const intl = useIntl()
  const analytics = useAnalytics()
  const helpScoutBeacon = useHelpScout()
  const { notifyError } = useResponseNotification()
  return {
    openHelp: () => {
      analytics.track('contact_support.open')
      if (!helpScoutBeacon) {
        notifyError(intl.formatMessage({ id: 'contact_support.contact.in_app_unavailable' }))
        return
      }
      helpScoutBeacon('open')
    },
  }
}

type ContactSupportProps = {
  admin?: boolean
}
export const ContactSupport = ({ admin }: ContactSupportProps) => {
  return (
    <>
      <Box mt={5} mb={4} component={Divider} />
      <Box textAlign="center">
        <Typography variant="body2" gutterBottom>
          <FormattedMessage id="contact_support.contact.label" />
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="center">
          {!admin && (
            <Typography variant="body2">
              <FormattedMessage id="contact_support.text_us_at" />
              &nbsp;
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            {admin ? (
              <>
                Please text or leave a message at{' '}
                <ExternalLink href="sms:+19295524625">(929) 552-4625</ExternalLink> for a call back
              </>
            ) : (
              <ExternalLink href="sms:+18884314355">(888) 431-4355</ExternalLink>
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          {!admin && (
            <Typography variant="body2">
              <FormattedMessage id="contact_support.email_us_at" />
              &nbsp;
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            {admin ? (
              <>
                Email{' '}
                <ExternalLink href="mailto:admin-support@thisisalice.com">
                  admin-support@thisisalice.com
                </ExternalLink>{' '}
                for a reply within 1 business day
              </>
            ) : (
              <ExternalLink href="mailto:support@thisisalice.com">support@thisisalice.com</ExternalLink>
            )}
          </Typography>
        </Box>
        <Box mt={2}>
          <UserHelpRequestForm />
        </Box>
      </Box>
    </>
  )
}
