import {
  ActionLayout,
  Button,
  Checkbox,
  ExternalLink,
  FormControlLabel,
  List,
  ListItem,
  Typography,
} from '@alice-financial/pretext-ui'
import { QueryKey } from '@tanstack/react-query'
import * as React from 'react'
import { ACH_ALLOWLIST, ACHNumber } from './achAllowList'
import { useAcceptACHMandate } from './useAcceptACHMandate'
import { ConfirmBankAccountSetupResponse } from './useConfirmBankAccount'

type ACHMandateProps<TResponse = unknown> = {
  handleConfirmationResponse: (response: ConfirmBankAccountSetupResponse) => TResponse
  clientSecret: string
  invalidationKeys: Array<QueryKey>
}

/**
 * This component display a mandatory agreement for ACH payments - Stripe must be notified that the account
 * keyed by client secret has accepted the ACH mandate, and then the response is handled by the provided
 * callback, which usually instantiates a corresponding payment method in our back end.
 *
 * Note that the invalidationKeys are required because the clientSecret will no longer be usable after
 * the ACH mandate is accepted
 */
export const ConfirmStripeACHConnection = ({
  clientSecret,
  handleConfirmationResponse,
  invalidationKeys,
}: ACHMandateProps) => {
  const { mutate: acceptAndPersist } = useAcceptACHMandate(handleConfirmationResponse, invalidationKeys)
  const onClick = () => acceptAndPersist({ clientSecret })
  return <ConfirmACHConnection onAccept={onClick} />
}

type ACHMandateSteps = 'confirm-ach-allowlist' | 'authorize-ach-transfer' | 'confirm-ach-mandate'
export const ConfirmACHConnection = ({ onAccept }: { onAccept: () => void }) => {
  const [step, setStep] = React.useState<ACHMandateSteps>('confirm-ach-allowlist')
  if (step === 'confirm-ach-allowlist') {
    return <ConfirmACHAllowlist onConfirm={() => setStep('authorize-ach-transfer')} />
  }
  if (step === 'authorize-ach-transfer') {
    return <AuthorizeACHTransfer onConfirm={() => setStep('confirm-ach-mandate')} />
  }
  if (step === 'confirm-ach-mandate') {
    return <ACHMandate onAccept={onAccept} />
  }
  return <ACHMandate onAccept={onAccept} />
}

type ConfirmACHCheckboxProps = {
  checked: boolean
  onChange: () => void
  label: React.ReactNode
}
const ConfirmACHCheckbox = ({ checked, onChange, label }: ConfirmACHCheckboxProps) => (
  <ListItem disablePadding disableGutters>
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} sx={{ py: 0.5 }} />}
      label={label}
      sx={{ '& .MuiFormControlLabel-label': { fontSize: '1rem' } }}
    />
  </ListItem>
)

type ConfirmACHState = Record<ACHNumber | 'failure-ack', boolean>
const confirmACHReducer = (
  state: ConfirmACHState,
  action: { type: 'change'; value: ACHNumber | 'failure-ack' }
) => ({ ...state, [action.value]: !state[action.value] })

const ConfirmACHAllowlist = ({ onConfirm }: { onConfirm: () => void }) => {
  const [checkedState, dispatch] = React.useReducer(
    confirmACHReducer,
    ACH_ALLOWLIST.reduce((acc, value) => ({ ...acc, [value]: false }), {
      'failure-ack': false,
    } as ConfirmACHState)
  )
  const allChecked = Object.values(checkedState).every(Boolean)
  return (
    <div>
      <List>
        {ACH_ALLOWLIST.map((achNumber) => (
          <ConfirmACHCheckbox
            key={achNumber}
            checked={checkedState[achNumber]}
            onChange={() => dispatch({ type: 'change', value: achNumber })}
            label={
              <>
                I have whitelisted ACH ID <strong>{achNumber}</strong> for{' '}
                <strong>Stripe Payments Company</strong>
              </>
            }
          />
        ))}
        <ConfirmACHCheckbox
          checked={checkedState['failure-ack']}
          onChange={() => dispatch({ type: 'change', value: 'failure-ack' })}
          label={
            <>
              I understand and agree that ACH rejections, declines, reversals, or failures result in
              unexpected disruptions to employee&apos;s benefits, noncompliance with applicable commuter
              benefit laws, significant costs to Alice, and will incur a $75 per transaction ACH Failure Fee.
            </>
          }
        />
      </List>
      <Typography variant="body2" gutterBottom>
        Note: You may wish to forward{' '}
        <ExternalLink href="https://docs.google.com/document/d/1Bab4yKF3mzIdldTAur4jvJObwy7IXX6UP9rB_IMsw2g/edit?tab=t.0">
          Whitelisting Alice&apos;s ACH IDs
        </ExternalLink>{' '}
        to your finance team.
      </Typography>

      <ActionLayout
        disablePadding
        primary={
          <Button variant="contained" onClick={onConfirm} disabled={!allChecked}>
            Confirm
          </Button>
        }
      />
    </div>
  )
}

const AuthorizeACHTransferCheckbox = [
  'authorize-deposit',
  'reserve-deposit-understand',
  'reserve-deposit-ack',
  'balance-detail-understand',
] as const
type AuthorizeACHTransferCheckbox = (typeof AuthorizeACHTransferCheckbox)[number]
type AuthorizeACHTransferState = Record<AuthorizeACHTransferCheckbox, boolean>
const authorizeACHTransferReducer = (
  state: AuthorizeACHTransferState,
  action: { type: 'change'; value: AuthorizeACHTransferCheckbox }
) => ({ ...state, [action.value]: !state[action.value] })
/**
 * Initiate Initial Contributions Account Transfer
:white_tick: I authorize a $100 Reserve Deposit to open and fund my organization's Contribution's Account.
:white_tick: I understand that this amount is owned by my organization, are not fees to Alice, and are not payments by your organization for your employees pretax expenses.
:white_tick: I acknowledge that this Initial Reserve Deposit and any future per employee Reserve Deposits will be returned to my organization at the conclusion of the service term and when enrolled employees are terminated, respectively.
:white_tick: I understand that the balance and transaction detail for my organization's Contribution Account are available on my Admin Dashboard and are emailed each month to me and any Finance Admins I designate on Email Preferences page.
 [Authorize Transfer]
 */
const AuthorizeACHTransfer = ({ onConfirm }: { onConfirm: () => void }) => {
  const [checkedState, dispatch] = React.useReducer(
    authorizeACHTransferReducer,
    AuthorizeACHTransferCheckbox.reduce(
      (acc, value) => ({ ...acc, [value]: false }),
      {} as AuthorizeACHTransferState
    )
  )
  const allChecked = Object.values(checkedState).every(Boolean)
  return (
    <div>
      <List>
        <ConfirmACHCheckbox
          checked={checkedState['authorize-deposit']}
          onChange={() => dispatch({ type: 'change', value: 'authorize-deposit' })}
          label={
            <>
              I authorize a $100 Reserve Deposit to open and fund my organizations Contribution&apos;s
              Account.
            </>
          }
        />
        <ConfirmACHCheckbox
          checked={checkedState['reserve-deposit-understand']}
          onChange={() => dispatch({ type: 'change', value: 'reserve-deposit-understand' })}
          label={
            <>
              I understand that this amount is owned by my organization, are not fees to Alice, and are not
              payments by your organization for your employees pretax expenses.
            </>
          }
        />
        <ConfirmACHCheckbox
          checked={checkedState['reserve-deposit-ack']}
          onChange={() => dispatch({ type: 'change', value: 'reserve-deposit-ack' })}
          label={
            <>
              I acknowledge that this Initial Reserve Deposit and any future per employee Reserve Deposits
              will be returned to my organization at the conclusion of the service term and when enrolled
              employees are terminated, respectively.
            </>
          }
        />
        <ConfirmACHCheckbox
          checked={checkedState['balance-detail-understand']}
          onChange={() => dispatch({ type: 'change', value: 'balance-detail-understand' })}
          label={
            <>
              I understand that the balance and transaction detail for my organization&apos;s Contribution
              Account are available on my Admin Dashboard and are emailed each month to me and any Finance
              Admins I designate on the Email preferences page.
            </>
          }
        />
      </List>
      <ActionLayout
        disablePadding
        primary={
          <Button variant="contained" onClick={onConfirm} disabled={!allChecked}>
            Authorize transfer
          </Button>
        }
      />
    </div>
  )
}

const ACHMandate = ({ onAccept }: { onAccept: () => void }) => (
  <>
    <Typography variant="h3" gutterBottom>
      Acknowledgements of Debits by Pretax Hero Inc. (dba Alice) to your Treasury Financial Account (AKA
      Benefits Contribution Account)
    </Typography>
    <Typography variant="body2" component="p" gutterBottom>
      By clicking [Accept], you authorize Pretax Hero Inc. (dba Alice or This is Alice) to debit the bank
      account specified above for any transfers required arising from your use of Pretax Hero Inc.&apos;s
      services, pursuant to Pretax Hero Inc.&apos;s website and terms, until this authorization is revoked.
    </Typography>
    <Typography variant="body2" component="p" gutterBottom>
      You may amend or cancel this authorization at any time by providing notice to Pretax Hero Inc. with 30
      (thirty) days notice.
    </Typography>
    <Typography variant="body2" component="p">
      If you use additional Pretax Hero Inc. services periodically pursuant to Pretax Hero Inc.&apos;s terms,
      you authorize Pretax Hero Inc. to debit your bank account periodically for those services. Payments that
      fall outside of the regular debits authorized above will only be debited after your authorization is
      obtained.
    </Typography>
    <ActionLayout
      disablePadding
      primary={
        <Button variant="contained" onClick={onAccept}>
          Accept
        </Button>
      }
    />
  </>
)
