import { UseGqlMutationCallbacks } from '@alice-financial/api'
import { useMutation } from '@tanstack/react-query'
import { useMutationNotifier } from '../../../utils/useMutationNotifier'
import { UserHelpRequestInput } from '../../graphql/generated.types'
import { UserHelpRequestMutation, useUserHelpRequestMutation } from './gql/userHelpRequest_gen'

type UseUserHelpRequestMutationOptions = UseGqlMutationCallbacks<
  UserHelpRequestMutation,
  UserHelpRequestInput
>
export const useUserHelpRequest = (mutationOptions?: UseUserHelpRequestMutationOptions) => {
  const { mutateAsync: requestHelp } = useUserHelpRequestMutation()
  const mutationOptionsWithNotification = useMutationNotifier({}, mutationOptions)
  return useMutation((input) => requestHelp({ input }), mutationOptionsWithNotification)
}
