import {
  ActionLayout,
  Button,
  Grid,
  InlineEmailInputController,
  InlineInputController,
} from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { cleanTelString } from '../../../../utils/formatters/telFormat'
import { GqlUserFragment, useCurrentUserQuery } from '../../../user/gql/currentUser_gen'
import { AdminSignupFormValues, useSignup } from '../useSignup'

const getValues = (user?: GqlUserFragment | null): AdminSignupFormValues => {
  return {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    preferredName: user?.preferredName ?? '',
    email: user?.email ?? '',
    tel: cleanTelString(user?.tel?.nationalNumber ?? ''),
    password: '',
    passwordConfirmation: '',
    acceptLegal: false,
    acceptEmployerTos: false,
  }
}

type CreateOrgAdminFormProps = {
  disabled?: boolean
}

export const CreateOrgAdminForm = ({ disabled }: CreateOrgAdminFormProps) => {
  const intl = useIntl()
  const { data: currentUserData } = useCurrentUserQuery()
  const currentUser = currentUserData?.currentUser

  const values = React.useMemo(() => getValues(currentUser), [currentUser]) // ensures currentUser data changes update the form
  const { control, handleSubmit, watch } = useForm<AdminSignupFormValues>({ values })
  const password = watch('password')
  const { mutate: signup } = useSignup()
  const onSubmit = handleSubmit((values) => signup(values))
  const formDisabled = Boolean(disabled || (currentUser && !currentUser.hasDummyPassword))
  return (
    <>
      <form data-testid="create-org-admin" onSubmit={onSubmit}>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12} sm={6}>
            <InlineInputController
              control={control}
              label="First name"
              name="firstName"
              autoComplete="given-name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.name.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InlineInputController
              control={control}
              label="Last name"
              name="lastName"
              autoComplete="family-name"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.name.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InlineInputController
              control={control}
              label="Preferred name"
              name="preferredName"
              autoComplete="given-name"
              helperText="What should we call you?"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.name.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InlineEmailInputController
              name="email"
              required
              control={control}
              label="Work email"
              autoComplete="username"
              InputLabelProps={{ shrink: true }}
              fullWidth
              disabled={formDisabled}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InlineInputController
              control={control}
              label="New password"
              name="password"
              type="password"
              autoComplete="new-password"
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={formDisabled}
              rules={{ required: intl.formatMessage({ id: 'profile.password.validation.required' }) }}
            />
          </Grid>
          <Grid item xs={12} sm={8}>
            <InlineInputController
              control={control}
              label="Confirm password"
              name="passwordConfirmation"
              type="password"
              autoComplete="new-password"
              fullWidth
              InputLabelProps={{ shrink: true }}
              disabled={formDisabled}
              rules={{
                validate: (value) =>
                  value === password || intl.formatMessage({ id: 'auth.reset_password.validation.no_match' }),
              }}
            />
          </Grid>
        </Grid>
        <ActionLayout
          mb={0}
          primary={
            <Button type="submit" variant="contained" fullWidth>
              Create account
            </Button>
          }
        />
      </form>
    </>
  )
}
