import { Box, Button, Grid, TextField, Typography } from '@alice-financial/pretext-ui'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useLocation } from 'react-router'
import { UserHelpRequestInput } from '../../graphql/generated.types'
import { useCurrentUserQuery } from '../gql/currentUser_gen'
import { useUserHelpRequest } from './useUserHelpRequest'

const NESTED_BUTTON_WIDTH = '6rem'

export const UserHelpRequestForm = () => {
  const { data: currentUserData } = useCurrentUserQuery()
  const location = useLocation()
  const { mutate: requestHelp, isSuccess, isLoading } = useUserHelpRequest()
  const { handleSubmit, register, watch } = useForm<UserHelpRequestInput>({
    defaultValues: {
      message: '',
      fromUrl: location.pathname,
    },
  })
  if (!currentUserData?.currentUser) return null
  const disabled = isSuccess || isLoading
  if (isSuccess) {
    return (
      <Typography variant="caption" component="p">
        <FormattedMessage id="contact_support.help_request.success" />
      </Typography>
    )
  }
  return (
    <>
      <Typography variant="caption" component="p" fontWeight="bold">
        <FormattedMessage id="contact_support.help_request.let_us_know" />
      </Typography>
      <form onSubmit={handleSubmit((values) => requestHelp(values))}>
        <Box sx={{ padding: 1 }}>
          <Grid
            container
            rowGap={2}
            alignItems="center"
            justifyContent="flex-end"
            maxWidth="30rem"
            margin="0 auto"
            position="relative"
          >
            <Grid item xs={12}>
              <TextField
                multiline
                {...register('message')}
                inputProps={{
                  maxLength: 160,
                  style: { paddingRight: `calc(${NESTED_BUTTON_WIDTH} + 1rem)` },
                }}
                fullWidth
                disabled={disabled}
              />
              <Button
                variant="contained"
                type="submit"
                disabled={!watch('message')}
                sx={({ spacing }) => ({
                  width: NESTED_BUTTON_WIDTH,
                  position: 'absolute',
                  right: '0.5rem', // position a little fiddly because of outlined input padding and positioning
                  bottom: 0,
                  top: spacing(0.8),
                })}
              >
                <FormattedMessage id="common.send" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}
